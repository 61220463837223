import React, { useEffect, useState } from "react";
import { RxChevronDown } from "react-icons/rx";

import { Link, useParams } from "react-router-dom";
// import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import AllserviceCard from "./AllserviceCard";
import {
  ChakraProvider,
  useToast,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import AdminNav from "../AdminNav/AdminNav";
import AdminLogin from "../AdminNav/Login";
export default function Allservices() {
  const [update, setUpdate] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState("");
  const [mdata, setMdata] = useState("");
  const [showService, setShowService] = useState(false);
  const [activeBT, setActiveBT] = useState(id);
  const toast = useToast();
  useEffect(() => {
    window.scroll(0, 0);
  }, [id]);
  useEffect(() => {
    setActiveBT(id);
    axios
      .get(`https://fierce-worm-boot.cyclic.app/service`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, [activeBT, id, update]);

  const deleteEvent = (id) => {
    axios
      .delete(`https://fierce-worm-boot.cyclic.app/service/${id}`)
      .then((res) => {
        setUpdate(!update);
        toast({
          title: "Service delete",
          description: "Your service delete successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description:
            "An error occurred while submitting the form. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };
  const handleSubmitEvent = (formData, id) => {
    axios
      .patch(`https://fierce-worm-boot.cyclic.app/service/${id}`, formData)
      .then((res) => {
        setUpdate(!update);
        toast({
          title: "Service updated",
          description: "Your service update successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description:
            "An error occurred while submitting the form. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };
  useEffect(() => {
    axios
      .get("https://fierce-worm-boot.cyclic.app/main")
      .then((res) => {
        setMdata(res.data);
      })
      .catch((err) => console.log(err));
  }, [id, update]);

  const onActive = (id) => {
    setActiveBT(id);
  };
  const token = localStorage.getItem("AdminToken");
  if (!token) {
    return <AdminLogin />;
  }
  return (
    <>
      <ChakraProvider>
        <AdminNav />
      </ChakraProvider>
      <div className="services" style={{ marginTop: "20px" }}>
        <div>
          {/* <div>
            <h1
              style={{
                color: "#DB4242",
                fontSize: "2.4rem",
                marginTop: "-10px",
              }}
              className="Puja"
            >
              Puja
            </h1>
          </div> */}

          {/* <div className="service-bt">
            <div className="active-route">
              <button onClick={() => setShowService(!showService)}>
                {activeBT}
                <RxChevronDown className="uparrow" />
              </button>
            </div>
            <span className={showService ? "showService" : "hideservice"}>
             

              {mdata &&
                mdata.map((el) => (
                  <Link to={`/admin/allservice/${el.smname}`}>
                    <button
                      className={activeBT == el.smname ? "active-bt" : ""}
                      onClick={() => {
                        onActive(el.smname);
                        setShowService(!showService);
                      }}
                    >
                      {el.smname}
                    </button>
                  </Link>
                ))}
            </span>
          </div> */}
          <ChakraProvider>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Event Name</Th>
                    <Th>Bookings</Th>
                    {/* <Th>Event Date</Th>
                      <Th>Payment</Th> */}
                    <Th>View</Th>
                    <Th>Edit</Th>
                    <Th>Delete</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data &&
                    data.map((el) => {
                      return (
                        <AllserviceCard
                          data={el}
                          Delete={deleteEvent}
                          Update={handleSubmitEvent}
                        />
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </ChakraProvider>
        </div>
        <br></br>
      </div>
    </>
  );
}
