import React, { useEffect, useState } from "react";
import BannerImage from "../BanneImage/BannerImage";
import { Link } from "react-router-dom";
import "./Contact.css";
import { MdEmail, MdCall } from "react-icons/md";
import { TfiYoutube } from "react-icons/tfi";
import { BiLogoLinkedin } from "react-icons/bi";
import axios from "axios";
import Swal from "sweetalert2";
export default function Contact() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      full_name: formData.firstName + " " + formData.lastName,
      phone: formData.phone,
      email: formData.email,
      message: formData.message,
    };
    axios
      .post("https://fierce-worm-boot.cyclic.app/event", {
        ...payload,
        eventName: "Contact",
      })
      .then((res) => {
        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          subject: "",
          message: "",
        });
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your form has been submitted successfully!",
          confirmButtonColor:
            "linear-gradient(rgb(29, 86, 153), rgb(29, 86, 153), rgb(52, 103, 163), rgb(74, 120, 173), rgb(74, 120, 173), rgb(52, 103, 163), rgb(29, 86, 153), rgb(29, 86, 153))",
          confirmButtonText: "OK",
        });
      })
      .catch((err) =>
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong. Please try again later!",
          confirmButtonColor:
            "linear-gradient(rgb(29, 86, 153), rgb(29, 86, 153), rgb(52, 103, 163), rgb(74, 120, 173), rgb(74, 120, 173), rgb(52, 103, 163), rgb(29, 86, 153), rgb(29, 86, 153))",
          confirmButtonText: "OK",
        })
      );
    // Replace 'YOUR_API_ENDPOINT' with the backend API endpoint to handle form submissions
  };
  return (
    <>
      <BannerImage data={{ title: "Contact", route: "Contact" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#009efb",
        }}
      >
        <div className="contact-div">
          <div>
            {/* <p
              style={{
                marginBottom: "-5px",
                fontWeight: "bold",
                color: "#222",
              }}
            >
              Contact Form
            </p> */}
            {/* <h2>Contact Form</h2> */}
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                  required
                />
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone Number"
                  required
                />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  required
                />
              </div>
              {/* <div className="form-group">
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Subject"
                  required
                />
              </div> */}
              <div className="form-group">
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  required
                />
              </div>
              <button type="submit" className="submit">
                Submit
              </button>
            </form>
          </div>
          <br />
          <div
            style={{
              backgroundImage:
                "linear-gradient(180deg, #011b32 30%, #009ffd 100%)",
              borderRadius: "10px",
              padding: "2%",
              color: "white",
              textAlign: "center",
            }}
          >
            <h2>PRCR Chartered Accountants</h2>
            <br></br>
            <div className="email-phone">
              <h5>
                <Link to="mailto:prcrindia@gmail.com">
                  <MdEmail className="email" /> prcrindia@gmail.com
                </Link>
              </h5>
              <h5>
                <Link to="tel:8919559216">
                  <MdCall className="email" /> +91-891-955-9216
                </Link>
              </h5>
              <h5>
                <Link to="https://www.youtube.com/@PRCRCharteredAccountants">
                  <TfiYoutube className="youtube-icon" />{" "}
                  @PRCRCharteredAccountants
                </Link>
              </h5>
              <h5>
                <Link to="https://www.linkedin.com/company/prcr-chartered-accountants/">
                  <BiLogoLinkedin className="linkdin-icon" />{" "}
                  prcr-chartered-accountants
                </Link>
              </h5>
            </div>
            {/* <h3 style={{ marginTop: "80px" }}>PRCR</h3> */}
            {/* <p>
              Afifa manzil, Road No. 10, Zahara Zahara Nagar, Banjara Hills,
              Hyderabad, Telangana 500034
            </p> */}
            <br></br>
          </div>
          <div>
            <div style={{ marginTop: "1.25rem" }}>
              <iframe
                style={{
                  width: "100%",
                  height: "250px",
                  border: "0",
                  borderRadius: "1.25rem",
                }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30454.81787094637!2d78.4411039!3d17.4188776!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97f88ecbbac1%3A0x82099186876f8d0b!2sP%20R%20C%20R%20CHARTERED%20ACCOUNTANTS!5e0!3m2!1sen!2sin!4v1690463166846!5m2!1sen!2sin"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
