import React, { useEffect } from "react";
import { BsTwitter, BsTelephoneFill, BsYoutube } from "react-icons/bs";
import { BiLogoLinkedin } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import AOS from "aos";
import "aos/dist/aos.css";
import "./AboutUs.css";
import rakesh from "./images/rakesh.png";
import BannerImage from "../BanneImage/BannerImage";
import chetan from "./images/chetan.png";
import raheem from "./images/raheem.png";
import img1 from "./images/IMG_8648.png";
import img2 from "./images/IMG-2.png";

import { useParams } from "react-router-dom";

const teamMembers = [
  {
    name: "CA Sai Chethan Reddy",
    imageSrc: chetan,
    description: [
      "Meet CA Sai Chethan Reddy, a distinguished professional and Partner at PRCR Chartered Accountants, renowned for exceptional expertise in internal audit, tax audit, and GST. With a proven track record in internal audit, he identifies operational inefficiencies and mitigates risks, providing valuable insights for process improvements. In tax audit, he optimizes tax strategies, adheres to statutory requirements, and facilitates effective tax planning. Moreover, his extensive GST knowledge ensures comprehensive guidance on implementation, compliance, and optimization. With strong communication skills and a client-centric approach, he is a trusted advisor, delivering personalized solutions and exceptional results.",
    ],
    qualifications: "CA",
    email: "cachethanreddy@gmail.com",
    linkedin: "https://www.linkedin.com/in/ca-chethan-reddy-65a76b247",
    phone: "8919559216",
    twitter: "https://twitter.com/raheempatan99",
  },
  {
    name: "CA Patan Raheem",
    imageSrc: raheem,
    description: [
      "Meet CA Patan Raheem, a highly skilled Partner at PRCR Chartered Accountants, renowned for excellence in tax audit, GST, and insolvency proceedings. With a deep understanding of tax laws, they ensure compliance and identify tax-saving opportunities. His expertise in GST provides comprehensive guidance on implementation and compliance, simplifying the complexities for businesses. Additionally, their proficiency in handling insolvency proceedings includes effective guidance through restructuring, liquidation, and bankruptcy processes, safeguarding creditors' interests. Known for exceptional attention to detail and clear communication. He offers practical solutions and tailored advice to each client.",
    ],
    qualifications: "CA",
    email: "capatanraheem@mail.ca.in",
    linkedin: "https://www.linkedin.com/in/ca-patan-raheem-843b0a165",
    phone: "8919559216",
    twitter: "https://twitter.com/raheempatan99",
  },
  {
    name: "Sairam Jyesta",
    imageSrc: img1,
    description: [
      "Meet Sairam Jyesta, a Semi Qualified Chartered Accountant and Cost Management Accountant, driven by a passion for Finance and Accounting. With a rich and diverse knowledge base, he excels in Direct Taxation, Indirect Taxation, Statutory Audit, and holds expert proficiency in IBC Laws. His expertise shines particularly in managing companies under the IBC framework across Entertainment and Hospitality, Manufacturing, and Automobile sectors. Sairam possesses an innate knack for detail, effortlessly dissecting intricate financial data and providing insightful analysis. His dedication and multifaceted skills make him an invaluable asset in navigating the complex terrain of finance.",
    ],
    qualifications: "CA",
    email: "capatanraheem@mail.ca.in",
    linkedin: "https://www.linkedin.com/in/ca-patan-raheem-843b0a165",
    phone: "8919559216",
    twitter: "https://twitter.com/raheempatan99",
  },
  {
    name: "Shaik Fazal Ahmed",
    imageSrc: img2,
    description: [
      "Meet Shaik Fazal Ahmed, a seasoned professional with a remarkable 8-year journey in client management in the realm of Account and Taxation. His expertise spans diverse industries, including hospitality, manufacturing, business trade, e-commerce, and IT. Specializing in client management, as well as Accounting and Taxation, Fazal brings unparalleled insight and proficiency to each engagement. His extensive experience and industry know-how make him an asset in managing intricate financial matters and nurturing successful client relationships.",
    ],
    qualifications: "CA",
    email: "capatanraheem@mail.ca.in",
    linkedin: "https://www.linkedin.com/in/ca-patan-raheem-843b0a165",
    phone: "8919559216",
    twitter: "https://twitter.com/raheempatan99",
  },

  {
    name: "Rakesh Dandi",
    imageSrc: rakesh,
    description: [
      "Meet Rakesh Dandi, a Semi Qualified Chartered Accountant and MBA Finance professional, boasting a diverse career marked by expertise in the realm of internal audit. With a focus on the manufacturing and process industry, he has successfully managed an array of process audits and internal audits. Rakesh's combined financial acumen and business insight make him a trusted resource for optimizing operational efficiencies and ensuring robust internal controls within organizations.",
    ],
    qualifications: "CA",
    email: "capatanraheem@mail.ca.in",
    linkedin: "https://www.linkedin.com/in/ca-patan-raheem-843b0a165",
    phone: "8919559216",
    twitter: "https://twitter.com/raheempatan99",
  },
];

const AboutUs = () => {
  // window.scroll(0, 0);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const { id } = useParams();
  setTimeout(() => {
    const element = document.getElementById(`about_${id}`);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scroll(0, 0);
    }
  }, 500);
  return (
    <>
      <BannerImage data={{ title: " About Us", route: "About" }} />
      <section className="about-us-section">
        <div className="about-us-container">
          <br></br>
          <div className="about-us-content" data-aos="fade-up">
            {/* <h2 style={{textAlign:'center'}}>About Us</h2> */}
            <div className="wpb_wrapper">
              <p>
                PRCR Chartered Accountants is a leading firm providing
                exceptional audit services to businesses of all sizes. With a
                relentless commitment to excellence, we have established
                ourselves as a trusted partner for organizations seeking
                accurate and insightful financial assessments. Our team of
                highly skilled professionals possesses extensive experience in
                conducting various audit engagements, including external
                financial statement audits, internal audits, compliance audits,
                due diligence reviews, forensic audits, and performance audits.
              </p>
              <p>
                We understand the importance of tailoring our solutions to meet
                the specific needs of each client, and our industry expertise
                allows us to navigate complex financial landscapes with ease.
                Beyond numbers, we focus on building long-term relationships
                with our clients, serving as trusted advisors for their
                sustainable growth. By leveraging advanced technologies and
                innovative methodologies, we deliver timely and reliable
                results, ensuring client satisfaction and providing tangible
                value. Our global reach enables us to ensure compliance in an
                interconnected business environment, bringing clarity, insight,
                and peace of mind to our clients. Choose PRCR Chartered
                Accountants to experience our unwavering commitment to your
                success and benefit from the highest level of professionalism
                and expertise in all your auditing requirements.
              </p>
            </div>
            {/* Add more content here */}
          </div>

          {teamMembers.map((teamMember, index) => (
            <div
              key={index}
              className="team-section"
              data-aos="fade-up"
              id={`about_${index + 1}`}
            >
              <h3>{teamMember.name}</h3>
              <h6>
                <span style={{ visibility: "hidden" }}>
                  {teamMember.qualifications}
                </span>
              </h6>
              <div className="team-member">
                <div className="wpb_wrapper">
                  <img className="about-img" src={teamMember.imageSrc} alt="" />
                  {teamMember.description.map((desc, idx) => (
                    <p key={idx}>{desc}</p>
                  ))}
                  {/* <p>
                    <i className="fa fa-envelope"></i> <strong>Email</strong> :{" "}
                    <a href={`mailto:${teamMember.email}`}>
                      {teamMember.email}
                    </a>
                  </p> */}
                </div>
                <div className="team-member-icons"></div>
                <div className="share">
                  <a href={`mailto:${teamMember.email}`}>
                    <HiOutlineMail />
                  </a>
                  <a href={teamMember.linkedin}>
                    <BiLogoLinkedin />
                  </a>
                  <a href={teamMember.twitter}>
                    <BsTwitter />
                  </a>
                  <a href={`tel:${teamMember.phone}`}>
                    <BsTelephoneFill />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default AboutUs;
