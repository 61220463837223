import React from 'react'
import { Link } from 'react-router-dom'

export default function Guides() {
  return (
    <>
    <div style={{width:"100%",height:"fit-content",borderRadius:"5px"}}>
       <span style={{fontSize:"12px"}}>September 21, 2020</span>
       <h3>Hello world Guides!</h3>
       <Link to="/" style={{color:"var(--orange)",fontWeight:"bold"}}>Read more</Link>
    </div>
    <div style={{width:"100%",height:"fit-content",borderRadius:"5px"}}>
    <span style={{fontSize:"12px"}}>August 28, 2020</span>
    <h3>Hello world!</h3>
       <Link to="/" style={{color:"var(--orange)",fontWeight:"bold"}}>Read more</Link>
    </div>
   </>
  )
}
