import React, { useEffect, useState } from "react";
import {
  // Button,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Grid,
  GridItem,
  Image,
  Select,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  Box,
  Text,
  StackDivider,
  ChakraProvider,
  useToast,
  Button,
} from "@chakra-ui/react";
import AdminNav from "../AdminNav/AdminNav";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./UserDetailsCard.css";
export default function UserDetailsCard() {
  const navigate = useNavigate();
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();
  const toast = useToast();
  const {
    isOpen: isOpenModal2,
    onOpen: onOpenModal2,
    onClose: onCloseModal2,
  } = useDisclosure();

  const {
    isOpen: isOpenModalHoro,
    onOpen: onOpenModalHoro,
    onClose: onCloseModalHoro,
  } = useDisclosure();
  const {
    isOpen: isOpenEditApoi1,
    onOpen: onOpenEditApoi1,
    onClose: onCloseEditApoi1,
  } = useDisclosure();
  const {
    isOpen: isOpenEditEvent1,
    onOpen: onOpenEditEvent1,
    onClose: onCloseEditEvent1,
  } = useDisclosure();

  const {
    isOpen: isOpenEditHoro,
    onOpen: onOpenEditHoro,
    onClose: onCloseEditHoro,
  } = useDisclosure();
  const {
    isOpen: isOpenUser,
    onOpen: onOpenUser,
    onClose: onCloseUser,
  } = useDisclosure();

  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { id } = useParams();
  const [appointments, setAppointments] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [userData, setUserData] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formData, setFormData] = useState({});
  const [update, setUpdate] = useState(false);
  const [horoData, setHoroData] = useState([]);
  const [isDeleteAlertOpenEvent, setIsDeleteAlertOpenEvent] = useState(false);
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name === "appointmentDate") {
      const [year, month, day] = value.split("-");
      const formattedDate = `${day}/${month}/${year}`;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formattedDate,
      }));
    } 
    else{
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
   
  };

  const hendelUserData = () => {
    axios
      .patch(
        `https://fierce-worm-boot.cyclic.app/users/${selectedEvent._id}`,
        formData
      )
      .then((res) => {
        setUpdate(!update);
        toast({
          title: "User updated",
          description: "Your user update successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onCloseUser();
        setFormData({});
      })
      .catch((err) => {
        toast({
          title: "Error",
          description:
            "An error occurred while submitting the form. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };
  const handleSubmitEvent = () => {
    axios
      .patch(
        `https://fierce-worm-boot.cyclic.app/event/${selectedEvent._id}`,
        formData
      )
      .then((res) => {
        setUpdate(!update);
        toast({
          title: "Event updated",
          description: "Your event update successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onCloseEditEvent1();
        setFormData({});
      })
      .catch((err) => {
        toast({
          title: "Error",
          description:
            "An error occurred while submitting the form. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    axios
      .get(`https://fierce-worm-boot.cyclic.app/users/${id}`)
      .then((res) => setUserData(res.data))
      .catch((err) => console.log(err));


    axios
      .get(`https://fierce-worm-boot.cyclic.app/event/${id}`)
      .then((res) => setEventData(res.data))
      .catch((err) => console.log(err));

  }, [update]);

  const onDeleteConfirmEvent = () => {
    axios
      .delete(`https://fierce-worm-boot.cyclic.app/event/${selectedEvent._id}`)
      .then((res) => {
        toast({
          title: "Event Deleted",
          description: "Your event  delete successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setUpdate(!update);
        setIsDeleteAlertOpenEvent(false);
        // Perform any necessary actions after successful deletion
      })
      .catch((err) => {
        toast({
          title: "Error",
          description:
            "An error occurred while submitting the form. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const onDeleteConfirmUser = () => {
    axios
      .delete(`https://fierce-worm-boot.cyclic.app/users/${userData[0]._id}`)
      .then((res) => {
        toast({
          title: "User Deleted",
          description: "Your user delete successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setUpdate(!update);
        setIsDeleteUserOpen(false);
        navigate("/admin/users");
        // Perform any necessary actions after successful deletion
      })
      .catch((err) => console.log(err));
  };

  const openEventModal = (event, model) => {
    setSelectedEvent(event);
    onOpenModal2();
  };

  const openUser = () => {
    setSelectedEvent(userData[0]);
    onOpenUser();
  };

  const openEditEvent = (event) => {
    setSelectedEvent(event);
    onOpenEditEvent1();
  };

  return (
    <ChakraProvider>
      <AdminNav />
      <Card>
        <CardHeader display="flex" justifyContent="space-between">
          <Heading size="xl">Client Report</Heading>
          <Box
            gap="2"
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Button
              _hover={{
                color: "orange",
                bg: "transparent",
              }}
              onClick={openUser}
              p={2}
              backgroundColor="#92d592"
              fontSize="10px"
            >
              Edit
            </Button>
            <Button
              _hover={{
                color: "orange",
                bg: "transparent",
              }}
              onClick={() => setIsDeleteUserOpen(true)}
              p={2}
              backgroundColor="red"
              color="white"
              fontSize="10px"
            >
              Delete
            </Button>
          </Box>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Heading
                size="md"
                textTransform="uppercase"
                textAlign="center"
                p={10}
              >
                Client Details
              </Heading>
              {userData && (
                <Grid
                  templateColumns={{ base: "1fr", md: "1fr 1fr", sm: "1fr" }}
                >
                  <TableContainer>
                    <Table variant="simple">
                      <Tbody textAlign="start">
                        <Tr width="fit-content">
                          <Th>Full name</Th>
                          <Td textAlign="start">{userData[0].full_name}</Td>
                        </Tr>

                        <Tr>
                          <Th>Phone</Th>
                          <Td>{userData[0].phone}</Td>
                        </Tr>
                        <Tr>
                          <Th>Email</Th>
                          <Td>
                            {userData[0].email ? userData[0].email : "Null"}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>

                  <TableContainer>
                    <Table variant="simple">
                      <Tbody textAlign="start">
                        <Tr>
                          <Th>paid amount</Th>
                          <Td>{userData[0].paidAmmount}</Td>
                        </Tr>
                        <Tr>
                          <Th>remain amount</Th>
                          <Td>{userData[0].remainAmmount}</Td>
                        </Tr>
                        <Tr>
                          <Th>total amount</Th>
                          <Td>
                            {userData[0].remainAmmount +
                              userData[0].paidAmmount}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Box>

            <Box>
              <Heading
                size="md"
                textTransform="uppercase"
                textAlign="center"
                p={10}
              >
                Pending Enquiries
              </Heading>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Full Name</Th>
                      <Th>Service Name</Th>
                      <Th>Enquiry Date</Th>
                      <Th>Payment</Th>
                      <Th>View Details</Th>
                      <Th>Edit</Th>
                      <Th>Delete</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {eventData &&
                      eventData.map((el) => {
                        if (el.eventStatus == "Pending") {
                          return (
                            <Tr>
                              <Td>{`${el.full_name}`}</Td>
                              <Th>{el.eventName ? el.eventName : "Contact"}</Th>
                              <Td>{el.appointmentDate?el.appointmentDate:"Any Date"}</Td>
                              <Td color={el.paymentStatus ? "green" : "red"}>
                                {el.paymentStatus ? "Paid" : "Not Paid"}
                              </Td>
                              <Td>
                                <Button
                                  _hover={{
                                    color: "orange",
                                    bg: "transparent",
                                  }}
                                  onClick={() => {
                                    openEventModal(el);
                                  }}
                                  bg="orange"
                                >
                                  View
                                </Button>
                              </Td>
                              <Td
                                onClick={() => {
                                  openEditEvent(el);
                                }}
                              >
                                <Button
                                  _hover={{
                                    color: "orange",
                                    bg: "transparent",
                                  }}
                                  bg="yellow"
                                >
                                  Edit
                                </Button>
                              </Td>
                              <Th>
                                <Button
                                  _hover={{
                                    color: "orange",
                                    bg: "transparent",
                                  }}
                                  onClick={() => {
                                    setSelectedEvent(el);
                                    setIsDeleteAlertOpenEvent(true);
                                  }}
                                  bg="red"
                                >
                                  Delete
                                </Button>
                              </Th>
                            </Tr>
                          );
                        }
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>

            <Box>
              <Heading
                size="md"
                textTransform="uppercase"
                textAlign="center"
                p={10}
              >
                Completed Enquiries
              </Heading>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Full Name</Th>
                      <Th>Service Name</Th>
                      <Th>Enquiry Date</Th>
                      <Th>Payment</Th>
                      <Th>View Details</Th>
                      <Th>Edit</Th>
                      <Th>Delete</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {eventData &&
                      eventData.map((el) => {
                        if (el.eventStatus == "Completed") {
                          return (
                            <Tr>
                              <Td>{`${el.full_name}`}</Td>
                              <Th>{el.eventName ? el.eventName : "Contact"}</Th>
                              <Td>{el.appointmentDate?el.appointmentDate:"Any Date"}</Td>
                              <Td color={el.paymentStatus ? "green" : "red"}>
                                {el.paymentStatus ? "Paid" : "Not Paid"}
                              </Td>
                              <Td>
                                <Button
                                  _hover={{
                                    color: "orange",
                                    bg: "transparent",
                                  }}
                                  onClick={() => {
                                    openEventModal(el);
                                  }}
                                  bg="orange"
                                >
                                  View
                                </Button>
                              </Td>
                              <Td>
                                <Button
                                  _hover={{
                                    color: "orange",
                                    bg: "transparent",
                                  }}
                                  onClick={() => {
                                    openEditEvent(el);
                                  }}
                                  bg="yellow"
                                >
                                  Edit
                                </Button>
                              </Td>
                              <Th>
                                <Button
                                  _hover={{
                                    color: "orange",
                                    bg: "transparent",
                                  }}
                                  onClick={() => {
                                    setSelectedEvent(el);
                                    setIsDeleteAlertOpenEvent(true);
                                  }}
                                  bg="red"
                                >
                                  Delete
                                </Button>
                              </Th>
                            </Tr>
                          );
                        }
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </CardBody>
      </Card>

      {/* Edit Event */}
      {selectedEvent && (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpenEditEvent1}
          onClose={onCloseEditEvent1}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              style={{
                background: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
                color: "white",
                textAlign: "center",
                borderRadius: "5px 5px 0 0",
              }}
            >
              Edit Enquiry
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Grid
                templateColumns={{ base: "1fr", md: "1fr 1fr", sm: "1fr" }}
                gap={4}
              >
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Full name</FormLabel>
                  <Input
                    name="fname"
                    ref={initialRef}
                    placeholder={selectedEvent.full_name}
                    onChange={handleInputChange}
                    value={formData.full_name}
                  />
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    placeholder={selectedEvent.email}
                    onChange={handleInputChange}
                    value={formData.email}
                  />
                </FormControl>
                <FormControl
                    gridColumn={{
                      base: "1 / span 2",
                      md: "auto",
                    }}
                  >
                    <FormLabel>Enquiry Date</FormLabel>
                    <Input
                      name="appointmentDate"
                      type="date"
                      border="1px solid black"
                      placeholder={formData.appointmentDate}
                      onChange={handleInputChange}
                      min={new Date().toISOString().slice(0, 10)}
                    />
                  </FormControl>
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Enquiry Status</FormLabel>
                  <Select
                    name="eventStatus"
                    value={formData.eventStatus || selectedEvent.eventStatus}
                    onChange={handleInputChange}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Completed">Completed</option>
                  </Select>
                </FormControl>

                {/* <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Date</FormLabel>
                  <Input
                    type="date"
                    name="eventDate"
                    placeholder={selectedEvent.eventDate}
                    onChange={handleInputChange}
                    // value={formData.eventDate}
                    min={new Date().toISOString().slice(0, 10)}
                  />
                </FormControl> */}

                {/* <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Time</FormLabel>
                  <Input
                    type="time"
                    name="eventTime"
                    placeholder={selectedEvent.eventTime}
                    onChange={handleInputChange}
                  />
                </FormControl> */}
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Payment</FormLabel>
                  <Select
                    name="paymentStatus"
                    value={
                      formData.paymentStatus || selectedEvent.paymentStatus
                    }
                    onChange={handleInputChange}
                    defaultValue={
                      selectedEvent.paymentStatus ? "paid" : "notPaid"
                    }
                  >
                    <option value={false}>Not Paid</option>
                    <option value={true}>Paid</option>
                  </Select>
                </FormControl>
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Amount</FormLabel>
                  <Input
                    placeholder={selectedEvent.ammount}
                    name="ammount"
                    type="number"
                    onChange={handleInputChange}
                    value={formData.ammount}
                  />
                </FormControl>
                {/* <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>City</FormLabel>
                  <Input
                    type="text"
                    name="city"
                    placeholder={
                      selectedEvent.city ? selectedEvent.city : "City"
                    }
                    onChange={handleInputChange}
                    value={formData.city}
                  />
                </FormControl> */}
                {/* <FormControl
                  gridColumn={{ base: "1 / span 2", md: "auto" }}
                  width="100%"
                >
                  <FormLabel>Upload Image</FormLabel>

                  <Input
                    type="file"
                    id="imageMobile"
                    name="image"
                    accept="image/*"
                    onChange={handleInputChange}
                    required
                  />
                </FormControl> */}
                {/* <FormControl
                  gridColumn={{ base: "1 / span 2", md: "1 / span 2" }}
                  width="100%"
                >
                  <FormLabel>Full address</FormLabel>
                  <Textarea
                    name="address"
                    placeholder={selectedEvent.address}
                    onChange={handleInputChange}
                    value={formData.address}
                  />
                </FormControl> */}

                {/* <FormControl
                  gridColumn={{ base: "1 / span 2", md: "1 / span 2" }}
                  width="100%"
                >
                  <FormLabel>Author Message</FormLabel>
                  <Textarea
                    defaultValue={selectedEvent.authorMessage}
                    name="authorMessage"
                    onChange={handleInputChange}
                    value={formData.authorMessage}
                  />
                </FormControl> */}

                {/* <FormControl
                  gridColumn={{ base: "1 / span 2", md: "1 / span 2" }}
                  width="100%"
                >
                  <FormLabel>Upload Image</FormLabel>
                
                  <Input
                    type="file"
                    id="imageMobile"
                    name="image"
                    accept="image/*"
                    onChange={handleInputChange}
                    required
                  />
                </FormControl> */}
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button
                _hover={{
                  color: "orange",
                  bg: "transparent",
                }}
                color="white"
                gb="brown"
                mr={3}
                onClick={() => handleSubmitEvent()}
                backgroundColor="gold"
              >
                Save
              </Button>
              <Button
                _hover={{
                  color: "orange",
                  bg: "transparent",
                }}
                onClick={onCloseEditEvent1}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {/* Details Event */}

      {selectedEvent && (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpenModal2}
          onClose={onCloseModal2}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              style={{
                background: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
                color: "white",
                textAlign: "center",
                borderRadius: "5px 5px 0 0",
              }}
            >
              Enquiry Details
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Grid
                templateColumns={{ base: "1fr", md: "1fr 1fr", sm: "1fr" }}
                gap={4}
              >
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Full Name</FormLabel>
                  <Box>{`${selectedEvent.full_name} `}</Box>
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Mobile number</FormLabel>
                  <Box>{selectedEvent.phone}</Box>
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Email</FormLabel>
                  <Box>{selectedEvent.email}</Box>
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Enquiry Status</FormLabel>
                  <Box
                  >
                    {selectedEvent.eventStatus}
                  </Box>
                 
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Enquiry Date</FormLabel>
                  <Box>{selectedEvent.appointmentDate?selectedEvent.appointmentDate:"Any Date"}</Box>
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Amount</FormLabel>
                  <Box>{selectedEvent.ammount}</Box>
                </FormControl>
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Payment Status</FormLabel>
                  <Box>
                    {selectedEvent.paymentStatus ? (
                      <span style={{ color: "green" }}>Paid</span>
                    ) : (
                      <span style={{ color: "red" }}>Not Paid</span>
                    )}
                  </Box>
                </FormControl>
              </Grid>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {selectedEvent && (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpenUser}
          onClose={onCloseUser}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              style={{
                background: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
                color: "white",
                textAlign: "center",
                borderRadius: "5px 5px 0 0",
              }}
            >
              Edit User
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Grid
                templateColumns={{ base: "1fr", md: "1fr 1fr", sm: "1fr" }}
                gap={4}
              >
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>First name</FormLabel>
                  <Input
                    name="fname"
                    ref={initialRef}
                    placeholder={selectedEvent.fname}
                    onChange={handleInputChange}
                    value={formData.fname}
                  />
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Last name</FormLabel>
                  <Input
                    name="lname"
                    placeholder={selectedEvent.lname}
                    onChange={handleInputChange}
                    value={formData.lname}
                  />
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    placeholder={selectedEvent.email}
                    onChange={handleInputChange}
                    value={formData.email}
                  />
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Full address</FormLabel>
                  <Input
                    name="address"
                    placeholder={selectedEvent.address}
                    onChange={handleInputChange}
                    value={formData.address}
                  />
                </FormControl>

                {/* <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Pin code</FormLabel>
                  <Input
                    name="pincode"
                    placeholder={selectedEvent.pincode}
                    onChange={handleInputChange}
                    value={formData.pincode}
                  />
                </FormControl> */}

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Date Of Birth</FormLabel>
                  <Input
                    type="date"
                    name="DOB"
                    value={formData.DOB}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Time Of Birth</FormLabel>
                  <Input
                    type="time"
                    name="TOB"
                    value={formData.TOB}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Place Of Birth</FormLabel>
                  <Input
                    name="POB"
                    placeholder="Enter Your Birth Place "
                    value={formData.POB}
                    onChange={handleInputChange}
                  />
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Nakshatram</FormLabel>
                  <Input
                    name="nakshatra"
                    placeholder="Enter Your Birth Place "
                    value={formData.nakshatra}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button
                _hover={{
                  color: "orange",
                  bg: "transparent",
                }}
                color="white"
                gb="brown"
                mr={3}
                onClick={() => hendelUserData()}
                backgroundColor="gold"
              >
                Save
              </Button>
              <Button
                _hover={{
                  color: "orange",
                  bg: "transparent",
                }}
                onClick={onCloseUser}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <AlertDialog
        isOpen={isDeleteUserOpen}
        leastDestructiveRef={finalRef}
        onClose={() => setIsDeleteUserOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this User?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                _hover={{
                  color: "orange",
                  bg: "transparent",
                }}
                ref={finalRef}
                onClick={() => setIsDeleteUserOpen(false)}
              >
                Cancel
              </Button>
              <Button
                _hover={{
                  color: "orange",
                  bg: "transparent",
                }}
                colorScheme="red"
                onClick={onDeleteConfirmUser}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isDeleteAlertOpenEvent}
        leastDestructiveRef={finalRef}
        onClose={() => setIsDeleteAlertOpenEvent(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this Enquiry?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                _hover={{
                  color: "orange",
                  bg: "transparent",
                }}
                ref={finalRef}
                onClick={() => setIsDeleteAlertOpenEvent(false)}
              >
                Cancel
              </Button>
              <Button
                _hover={{
                  color: "orange",
                  bg: "transparent",
                }}
                colorScheme="red"
                onClick={onDeleteConfirmEvent}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ChakraProvider>
  );
}
