import React, { useEffect, useState } from "react";
import AdminNav from "../AdminNav/AdminNav";
import {
  SimpleGrid,
  ChakraProvider,
  useToast,
  Button,
  Select,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
} from "@chakra-ui/react";
import EventCard from "./EventsCard/EventCard";
import { BsSearch } from "react-icons/bs";
import { IconButton, HStack, VStack, Box } from "@chakra-ui/react";
import axios from "axios";
import { useParams } from "react-router-dom";
import AdminLogin from "../AdminNav/Login";

function Events() {
  const { id } = useParams();
  const [eventData, setEventData] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(8);
  const [update, setUpdate] = useState(false);
  const [payment, setPayment] = useState("");
  const [totalAppointment, setTotalAppointment] = useState(0);
  const [pendingAppointment, setPandingAppointment] = useState(0);
  const [completedAppointment, setCompletedAppointment] = useState(0);
  const toast = useToast();
  useEffect(() => {
    axios
      .get(`https://fierce-worm-boot.cyclic.app/event/`)
      .then((res) => setTotalAppointment(res.data.length))
      .catch((err) => console.log(err));

    axios
      .get(`https://fierce-worm-boot.cyclic.app/event/?query=Pending`)
      .then((res) => setPandingAppointment(res.data.length))
      .catch((err) => console.log(err));

    axios
      .get(`https://fierce-worm-boot.cyclic.app/event/?query=Completed`)
      .then((res) => setCompletedAppointment(res.data.length))
      .catch((err) => console.log(err));
  }, [update]);
  useEffect(() => {
    if (search === "Paid") {
      axios
        .get(`https://fierce-worm-boot.cyclic.app/event/?query=`)
        .then((res) => {
          let data = res.data.filter((el) => {
            return el.paymentStatus;
          });
          setEventData(data);
        })
        .catch((err) => console.log(err));
    } else if (search === "Not Paid") {
      axios
        .get(`https://fierce-worm-boot.cyclic.app/event/?query=`)
        .then((res) => {
          let data = res.data.filter((el) => {
            return !el.paymentStatus;
          });
          setEventData(data);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(`https://fierce-worm-boot.cyclic.app/event/?query=${search}`)
        .then((res) => setEventData(res.data))
        .catch((err) => console.log(err));
    }
  }, [search, update]);
  const handleSubmitEvent = (formData, id) => {
    axios
      .patch(`https://fierce-worm-boot.cyclic.app/event/${id}`, formData)
      .then((res) => {
        setUpdate(!update);
        toast({
          title: "Event Updated",
          description: "Your event Update has been submitted successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description:
            "An error occurred while submitting the form. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const onDeleteConfirmEvent = (id) => {
    axios
      .delete(`https://fierce-worm-boot.cyclic.app/event/${id}`)
      .then((res) => {
        setUpdate(!update);
        toast({
          title: "Event Deleted",
          description: "Your event Delete  successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Perform any necessary actions after successful deletion
      })
      .catch((err) => console.log(err));
  };
  // Get current events based on pagination
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = eventData.slice(indexOfFirstEvent, indexOfLastEvent);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const goToNextPage = () => {
    if (currentPage < Math.ceil(eventData.length / eventsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const token = localStorage.getItem("AdminToken");
  if (!token) {
    return <AdminLogin />;
  }
  return (
    <ChakraProvider>
      <AdminNav />
      <Box display="flex" justifyContent="space-between">
        <Box
          borderBottom="1px solid black"
          width="fit-content"
          minWidth="100px"
          margin="auto"
          marginTop="20px"
          display="flex"
          alignItems="center"
        >
          <input
            onChange={(e) => setSearch(e.target.value)}
            style={{
              height: "100%",
              border: "none",
              width: "100%",
              borderColor: "transparent",
              outline: "none",
              fontSize: "20px",
              marginLeft: "10px",
            }}
            placeholder="Search"
            type="search"
          />
          <IconButton
            aria-label="Search database"
            bg="none"
            icon={<BsSearch />}
          />
        </Box>
        <Box marginTop="20px">
          <Select onChange={(e) => setSearch(e.target.value)}>
            <option value="">All</option>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Paid">Paid</option>
            <option value="Not Paid">Not Paid</option>
          </Select>
        </Box>
      </Box>
      <Box mt={6} position="sticky" top="60px" zIndex="99">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th bg={"blue"} color={"white"}>
                  Enquiry : {totalAppointment}
                </Th>
                <Th bg={"red"} color={"white"}>
                  Pending : {pendingAppointment}
                </Th>
                <Th bg={"green"} color={"white"}>
                  Completed : {completedAppointment}
                </Th>
              </Tr>
            </Thead>
          </Table>
        </TableContainer>
      </Box>
      <SimpleGrid
        p="3"
        spacing={4}
        gap={5}
        templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
      >
        {currentEvents.length > 0 &&
          currentEvents.map((evt) => (
            <EventCard
              key={evt._id}
              event={evt}
              Update={handleSubmitEvent}
              Delete={onDeleteConfirmEvent}
            />
          ))}
      </SimpleGrid>
      <VStack mt="4" spacing="2">
        <HStack>
          <Button
            onClick={goToPreviousPage}
            isDisabled={currentPage === 1}
            variant="outline"
          >
            Previous
          </Button>
          {Array.from(
            { length: Math.ceil(eventData.length / eventsPerPage) },
            (_, index) => (
              <Button
                key={index}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
                variant={currentPage === index + 1 ? "solid" : "outline"}
              >
                {index + 1}
              </Button>
            )
          )}
          <Button
            onClick={goToNextPage}
            isDisabled={
              currentPage === Math.ceil(eventData.length / eventsPerPage)
            }
            variant="outline"
          >
            Next
          </Button>
        </HStack>
      </VStack>
      <br></br>
    </ChakraProvider>
  );
}

export default Events;
