import React, { useEffect, useState } from "react";
import "./Admin.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AdminNav from "./AdminNav";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const login = () => {
    let isValid = true;
    setUsernameError("");
    setPasswordError("");

    if (username.trim() === "") {
      setUsernameError("Please enter your username");
      isValid = false;
    }

    if (password.trim() === "") {
      setPasswordError("Please enter your password");
      isValid = false;
    }

    if (isValid) {
      const payload = { email: username, password };
      axios
        .post("https://fierce-worm-boot.cyclic.app/admin/login", payload)
        .then((res) => {
          if (res.data === "64c10f666bc5ba43594c715e") {
            localStorage.setItem("AdminToken", res.data);
            toast.success("Login successful");
            navigate("/admin/Users");
          } else {
            toast.error("Wrong password or email");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("AdminToken");
    if (token === "64c10f666bc5ba43594c715e") {
      navigate("/admin/Users");
    }
  }, []);

  return (
    <>
      <AdminNav />
      <div className="loginDiv">
        <div id="loginform">
          <h2 id="headerTitle">Admin Login</h2>
          <div>
            <div className="row">
              <label>Username</label>
              <input
                type="text"
                placeholder="Enter your username"
                onChange={(e) => setUsername(e.target.value)}
              />
              {usernameError && <p className="error">{usernameError}</p>}
            </div>
            <div className="row">
              <label>Password</label>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="password" // Change the input type to password
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  style={{ paddingRight: "2.5rem" }}
                />
              </div>
              {passwordError && <p className="error">{passwordError}</p>}
            </div>
            <div id="button" className="row">
              <button onClick={login}>Log in</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminLogin;
