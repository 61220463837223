import React, { useState } from "react";
import "./product.css";
import { useNavigate } from "react-router-dom";
export default function ProductCard({ data,index }) {
  const navigate=useNavigate()
  return (
    <div onClick={()=>navigate(`/auditAndCompliance/${index+1}`)} className="card-div" data-aos="fade-up" style={{borderRadius:"10px"}}>
      <h3>{data.h1}</h3>
      <p>{data.p}</p>
    </div>
  );
}
