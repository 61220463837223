import React, { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  ChakraProvider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import {
  Card,
  CardBody,
  CardFooter,
  Button,
  Stack,
  Heading,
  Divider,
  Image,
  ButtonGroup,
} from "@chakra-ui/react";

import axios from "axios";
import { Link } from "react-router-dom";
import "./AllserviceCard.css";
export default function AllserviceCard({ data, Delete, Update }) {
  const [isDeleteAlertOpenEvent, setIsDeleteAlertOpenEvent] = useState(false);
  const [totalEvent, setTotalEvent] = useState(0);
  const {
    isOpen: isOpenEditEvent1,
    onOpen: onOpenEditEvent1,
    onClose: onCloseEditEvent1,
  } = useDisclosure();
  const [formData, setFormData] = useState({});
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      let imageD = e.target.files[0];
      let imageUrl = "";
      try {
        const imageData = new FormData();
        imageData.append("file", imageD);
        imageData.append("upload_preset", "tekxwxfz"); // Replace with your Cloudinary unsigned upload preset
        console.log(imageData, value);
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/dzidj8k7j/auto/upload`,
          imageData
        );
        imageUrl = response.data.secure_url;
        console.log("url", imageUrl);
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: imageUrl,
        }));
      } catch (error) {
        console.error(error);
        return;
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    // Here, you can use the formData object as the payload
    // to send it to your backend or perform any necessary actions
    axios
      .post("https://fierce-worm-boot.cyclic.app/event", {
        ...formData,
        eventName: data.ssname,
        eventId: data._id,
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    setFormData({
      fname: "",
      lname: "",
      phone: "",
      eventDate: "",
      address: "",
      district: "",
      pincode: "",
      message: "",
    });
  };
  useEffect(() => {
    axios
      .get(`https://fierce-worm-boot.cyclic.app/event/users/${data._id}`)
      .then((res) => setTotalEvent(res.data.length))
      .catch((err) => console.log(err));
  }, []);

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <ChakraProvider>
      <Tr>
        <Th>{data.title}</Th>
        <Td>{totalEvent}</Td>

        <Td>
          <Link to={`/admin/userEvents/${data._id}`}>
            <Button bg="orange">View</Button>
          </Link>
        </Td>
        <Td
          onClick={() => {
            // openEditEvent(el);
          }}
        >
          <Button onClick={onOpenEditEvent1} bg="yellow">
            Edit
          </Button>
        </Td>
        <Th>
          <Button
            onClick={() => {
              // setSelectedEvent(el);
              setIsDeleteAlertOpenEvent(true);
            }}
            bg="red"
          >
            Delete
          </Button>
        </Th>
      </Tr>

      <AlertDialog
        isOpen={isDeleteAlertOpenEvent}
        leastDestructiveRef={finalRef}
        onClose={() => setIsDeleteAlertOpenEvent(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this Event?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={finalRef}
                onClick={() => setIsDeleteAlertOpenEvent(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setIsDeleteAlertOpenEvent(false);
                  Delete(data._id);
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpenEditEvent1}
        onClose={onCloseEditEvent1}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            style={{
              background: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
              color: "white",
              textAlign: "center",
              borderRadius: "5px 5px 0 0",
            }}
          >
            Edit Service {data.ssname}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Grid
              templateColumns={{ base: "1fr", md: "1fr 1fr", sm: "1fr" }}
              gap={4}
            >
              <FormControl
                gridColumn={{ base: "1 / span 2", md: "1 / span 2" }}
              >
                <FormLabel>Title</FormLabel>
                <Input
                  name="title"
                  ref={initialRef}
                  placeholder={data.title}
                  onChange={handleInputChange}
                  value={formData.title}
                />
              </FormControl>

              {/* <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Image</FormLabel>
                <Input
                  type="file"
                  id="imageMobile"
                  name="image"
                  accept="image/*"
                  onChange={handleInputChange}
                  required
                />
              </FormControl>

              <FormControl
                gridColumn={{ base: "1 / span 2", md: "1 / span 2" }}
              >
                <FormLabel>Purpose</FormLabel>
                <Textarea
                  type="text"
                  name="purpose"
                  placeholder="Purpose"
                  onChange={handleInputChange}
                  value={formData.purpose}
                />
              </FormControl>

              <FormControl
                gridColumn={{ base: "1 / span 2", md: "1 / span 2" }}
              >
                <FormLabel>Description</FormLabel>
                <Textarea
                  name="description"
                  placeholder="Description"
                  onChange={handleInputChange}
                  value={formData.address}
                />
              </FormControl>

              <FormControl
                gridColumn={{ base: "1 / span 2", md: "1 / span 2" }}
              >
                <FormLabel>Short Description</FormLabel>
                <Textarea
                  name="sdescription"
                  placeholder="Short Description"
                  onChange={handleInputChange}
                  value={formData.sdescription}
                />
              </FormControl>

              <FormControl
                gridColumn={{ base: "1 / span 2", md: "1 / span 2" }}
              >
                <FormLabel>What We Do</FormLabel>
                <Textarea
                  placeholder="Enter What We Do"
                  name="wedo"
                  onChange={handleInputChange}
                  value={formData.wedo}
                />
              </FormControl> */}
            </Grid>
          </ModalBody>

          <ModalFooter>
            <Button
              _hover={{
                color: "orange",
                bg: "transparent",
              }}
              color="white"
              gb="brown"
              mr={3}
              o
              onClick={() => {
                Update(formData, data._id);
                onCloseEditEvent1();
                setFormData({});
              }}
              backgroundColor="gold"
            >
              Save
            </Button>
            <Button
              _hover={{
                color: "orange",
                bg: "transparent",
              }}
              onClick={onCloseEditEvent1}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}
