import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Slider.css";
import background from "./home_backgroundv2.jpg";

const data = [
  {
    h1: "Virtual CFO",
    p: "Expertly preparing and presenting financial statements, designing accounting systems, and offering comprehensive financial analysis to support informed decision-making for businesses.",
  },
  {
    h1: "Audit and Assurance",
    p: "Ensuring financial integrity through external and internal audits, compliance checks, due diligence reviews, and specialized audits for robust risk management.",
  },
  {
    h1: "Taxation",
    p: "Providing strategic tax planning, filing tax returns, handling assessments, and offering expert guidance on tax implications for businesses and individuals.",
  },
  {
    h1: "Management Consulting",
    p: "Offering tailored financial and strategic planning, cost analysis, risk management, and the design of effective internal control systems.",
  },
  {
    h1: "Corporate Finance and Advisory",
    p: "Guiding businesses through mergers, acquisitions, divestitures, and financial due diligence, with a focus on corporate governance and compliance.",
  },
  {
    h1: "Insolvency and Bankruptcy",
    p: "Assisting with insolvency proceedings, restructuring, and forensic investigations related to insolvency matters, along with expert guidance on bankruptcy laws.",
  },
  {
    h1: "Financial and Investment Analysis",
    p: "Providing investment advisory services, portfolio management, financial market research, and risk assessment to optimize investment decisions.",
  },
  {
    h1: "Education and Training",
    p: "Conducting seminars, workshops, and training sessions on accounting, finance, and taxation to empower individuals and businesses with essential financial knowledge.",
  },
];

export default function Slider() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <>
      <Carousel
        id="carouselExampleControls"
        activeIndex={activeIndex}
        onSelect={handleSelect}
        className="main_container"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {data.map((el, index) => (
          <Carousel.Item interval={1500} key={index}>

            <div
              className="heading_container_slide"
              style={{
                display: "grid",
              }}
            >
              <div className="heading_content">
                <h2 className="heading">{el.h1}</h2>
                <p className="text">{el.p}</p>
              </div>
              <div className="top_clousal_img" style={{ visibility: 'hidden' }}>
                {/* <img src={el.slide} alt={`Slide ${index + 1}`} /> */}
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
}