import React, { useEffect, useState } from "react";
// import "./Viwe.css";
import BannerImage from "../BanneImage/BannerImage";
import { BsYoutube } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import Ebooks from "./Ebooks";
import Faq from "./Faq";
import Guides from "./Guides";
import Blog from "./Blog";
import ExternalLink from "./ExternalLink";
export default function Resources() {
  window.scroll(0, 0);
  const { id } = useParams();

  const navigate = useNavigate();
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const onFilter = (data) => {
    setFilter(data);
    setCurrentPage(1);
  };

  const data = [
    {
      img: "https://i.ytimg.com/vi/PD_pldiVZRc/sddefault.jpg",
      title: "It ",
      cetegory: "It",
    },
    {
      img: "https://i.ytimg.com/vi/PD_pldiVZRc/sddefault.jpg",
      title: "It",
      cetegory: "It",
    },
    {
      img: "https://i.ytimg.com/vi/PD_pldiVZRc/sddefault.jpg",
      title: "science",
      cetegory: "science",
    },
    {
      img: "https://i.ytimg.com/vi/PD_pldiVZRc/sddefault.jpg",
      title: "science",
      cetegory: "science",
    },
    {
      img: "https://i.ytimg.com/vi/PD_pldiVZRc/sddefault.jpg",
      title: "Ashiq",
      cetegory: "Ashiq",
    },
    {
      img: "https://i.ytimg.com/vi/PD_pldiVZRc/sddefault.jpg",
      title: "Ashiq",
      cetegory: "Ashiq",
    },
  ];

  return (
    <>
      <BannerImage data={{ title: id, route: id }} />
      <div className="View-main">
        <div className="view">
          <div className="view-content" style={{ width: "100%" }}>
            <div
              className="view-card"
              style={{
                gridTemplateColumns:
                  id === "FAQ's" || id == "Blog" ? "1fr" : "",
                width: "100%",
              }}
            >
              {id == "Ebooks" && <Ebooks />}
              {id == "FAQ's" && <Faq />}
              {id == "Guides" && <Guides />}
              {id == "Blog" && <Blog />}
              {id == "ExternalLink" && <ExternalLink />}
            </div>
          </div>
          <div className="view-filter">
            <div>
              <h3>Resources</h3>
              <div>
                <p
                  onClick={() => {
                    navigate("/resources/Ebooks");
                  }}
                  style={{
                    backgroundColor: id == "Ebooks" ? "var( --orange)" : "",
                    color: id == "Ebooks" ? "white" : "",
                  }}
                >
                  Ebooks
                </p>
                <p
                  onClick={() => {
                    navigate("/resources/FAQ's");
                  }}
                  style={{
                    backgroundColor: id == "Faq" ? "var( --orange)" : "",
                    color: id == "Faq" ? "white" : "",
                  }}
                >
                  FAQ's
                </p>
                <p
                  onClick={() => {
                    navigate("/resources/Guides");
                  }}
                  style={{
                    backgroundColor: id == "Guides" ? "var( --orange)" : "",
                    color: id == "Guides" ? "white" : "",
                  }}
                >
                  Guides
                </p>
                <p
                  onClick={() => {
                    navigate("/resources/Blog");
                  }}
                  style={{
                    backgroundColor: id == "Blog" ? "var( --orange)" : "",
                    color: id == "Blog" ? "white" : "",
                  }}
                >
                  Blog
                </p>
                <p
                  onClick={() => {
                    navigate("/resources/ExternalLink");
                  }}
                  style={{
                    backgroundColor:
                      id == "ExternalLink" ? "var( --orange)" : "",
                    color: id == "ExternalLink" ? "white" : "",
                  }}
                >
                  Useful Links
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
