import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelopeOpen,
  FaFacebookF,
  FaTwitter,
  FaGooglePlusG,
  FaTelegramPlane,
  FaYoutube,
} from "react-icons/fa";
import {
  IoIosMail,
  IoLogoLinkedin,
  IoLogoYoutube,
  IoLogoTwitter,
} from "react-icons/io";
import log from "../Navbar/logo-no-background.png";
import "./Footer.css";
const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    // Add event listener to update isMobile on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <footer className="footer-section">
      <div className="container">
        {/* <div className="footer-cta pt-5 pb-5">
          <div className="row">
            <div
              className="col-xl-4 col-md-4 mb-30"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <div className="single-cta">
                <FaPhone style={{ color: "white" }}></FaPhone>
                <div className="cta-text">
                  <h4>Call us</h4>
                  <span>+91-891-955-9216</span>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-4 mb-30"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <div className="single-cta">
                <FaEnvelopeOpen style={{ color: "white" }}></FaEnvelopeOpen>
                <div className="cta-text">
                  <h4>Mail us</h4>
                  <span>prcrindia@gmail.com</span>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-4 mb-30"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <div className="single-cta">
                <FaMapMarkerAlt style={{ color: "white" }}></FaMapMarkerAlt>
                <div className="cta-text">
                  <h4>Find us</h4>
                  <span>
                    Afifa manzil, Road No. 10, Zahara Zahara Nagar, Banjara
                    Hills, Hyderabad, Telangana 500034
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-50" data-aos="zoom-in">
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to="/">
                    <img src={log} className="img-fluid" alt="logo" />
                  </Link>
                </div>
                <div className="footer-text">
                  <p>
                    <span>
                      <strong>
                        A Young and Dynamic Consulting Firm.Partnering for
                        {!isMobile ? (
                          <>
                            <br></br>
                          </>
                        ) : (
                          " "
                        )}
                        Financial Excellence - Your Trusted Path to Success!
                      </strong>
                    </span>
                    <br></br>
                    <br></br>
                    <Link to="tel:9701449349" style={{display:"flex",alignItems:"center",gap:"10px"}}>
                      <FaPhone style={{ color: "white" }}></FaPhone>
                      <strong> CA Sai Chethan Reddy : +91-970-144-9349</strong>
                    </Link>
                    {/* <br></br> */}
                    <Link to="tel:8919559216" style={{display:"flex",alignItems:"center",gap:"10px"}}>
                      <FaPhone style={{ color: "white" }}></FaPhone>
                      <strong> CA Patan Raheem : +91-891-955-9216</strong>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 mb-30"
              data-aos="zoom-in"
            >
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul style={{ fontWeight: "bold" }}>
                  <li>
                    <Link to="/">Home</Link>
                  </li>

                  <li>
                    <Link to="/auditAndCompliance/1">Virtual CFO</Link>
                  </li>

                  <li>
                    <Link to="/aboutus/all">About us</Link>
                  </li>
                  <li>
                    <Link to="/auditAndCompliance/2">Audit</Link>
                  </li>
                  <li>
                    <Link to="/auditAndCompliance/1">Our Services</Link>
                  </li>

                  <li>
                    <Link to="/auditAndCompliance/3">Taxation</Link>
                  </li>
                  <li>
                    <Link to="/resources/Ebooks">Resources</Link>
                  </li>
                  <li>
                    <Link to="/auditAndCompliance/7">Finance</Link>
                  </li>

                  <li>
                    <Link to="/contact">Contact us</Link>
                  </li>

                  <li>
                    <Link to="/auditAndCompliance/6">Insolvency</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 mb-50"
              data-aos="zoom-in"
            >
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Subscribe</h3>
                </div>
                <div className="footer-text mb-25">
                  <p>
                    Don’t miss to subscribe to our new feeds, kindly fill the
                    form below.
                  </p>
                </div>
                <div className="subscribe-form">
                  <form action="#">
                    <input type="text" placeholder="Email Address" />
                    <button type="submit">
                      <FaTelegramPlane className="plane-icon" />
                    </button>
                  </form>
                </div>
                <br></br>
                <div className="footer-social-icon">
                  <Link to="mailto:prcrindia@gmail.com">
                    <IoIosMail
                      className="facebook-bg"
                      style={{ fontSize: "150%", margin: "1%" }}
                    />
                  </Link>
                  <Link to="#">
                    <IoLogoTwitter
                      className="twitter-bg"
                      style={{ fontSize: "150%", margin: "1%" }}
                    />
                  </Link>
                  <Link to="https://www.linkedin.com/company/prcr-chartered-accountants/">
                    <IoLogoLinkedin
                      className="google-bg"
                      style={{ fontSize: "150%", margin: "1%" }}
                    />
                  </Link>
                  <Link to="https://www.youtube.com/@PRCRCharteredAccountants">
                    <IoLogoYoutube style={{ fontSize: "150%", margin: "1%" }} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <div className="copyright-text">
                <p>Copyright &copy; 2018, All Right Reserved <a href="https://codepen.io/anupkumar92/">Lorem</a></p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
              <div className="footer-menu">
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/terms">Terms</Link></li>
                  <li><Link to="/privacy">Privacy</Link></li>
                  <li><Link to="/policy">Policy</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;
