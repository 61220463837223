import React from 'react'
import { Link } from 'react-router-dom'

export default function Ebooks() {
  return (
    <>
     <div style={{width:"100%",height:"fit-content",borderRadius:"5px"}}>
        <span style={{fontSize:"12px"}}>September 24, 2020</span>
        <h3>Vendor Risk Management1</h3>
        <Link to="/" style={{color:"var(--orange)",fontWeight:"bold"}}>Read more</Link>
     </div>
     <div style={{width:"100%",height:"fit-content",borderRadius:"5px"}}>
     <span style={{fontSize:"12px"}}>September 24, 2020</span>
     <h3>Vendor Risk Management1</h3>
        <Link to="/" style={{color:"var(--orange)",fontWeight:"bold"}}>Read more</Link>
     </div>
    </>
   
  )
}
