import React from "react";
import { Link } from "react-router-dom";
import "./Faq.css";
export default function Blog() {
  return (
    <>
      <div id="blog-div" style={{ height: "fit-content", borderRadius: "5px" }}>
        <span style={{ fontSize: "12px" }}>September 24, 2020</span>
        <h3>In this era of disruptive technology and innovative</h3>
        <p>
          In this era of disruptive technology and innovative business models,
          every business needs to re-look into their Governance, Risk and
          Compliance. This requires an understanding of today's reality and
          meeting the expectations of tomorrow. We are a professional firm, with
          a right combination of technology and processes with a firm
          understanding of compliance to deliver valuable business insights.
        </p>
        <Link to="/" style={{ color: "var(--orange)", fontWeight: "bold" }}>
          Read more
        </Link>
      </div>
      <div id="blog-div" style={{ height: "fit-content", borderRadius: "5px" }}>
        <span style={{ fontSize: "12px" }}>September 24, 2020</span>
        <h3>Lord Ganesha, and his relevance in the post-pandemic world!</h3>
        <p style={{fontWeight:"bold"}}>
          While the celebrations of “Ganesh Chaturthi” this year have been muted
          owing to COVID, sharing a few thoughts, on what Lord Ganesha teaches
          us in the current situation!
        </p>
        <p>
          Lord Ganesha, known to be the remover of obstacles (Vigna-vinashaka)
          is often the first to be worshipped to ensure a promising beginning
          for every start. From technology parlance, he symbolizes the booting
          of any system to ensure they are ready for operation.
        </p>
        <Link to="/" style={{ color: "var(--orange)", fontWeight: "bold" }}>
          Read more
        </Link>
      </div>
    </>
  );
}
