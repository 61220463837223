import React from "react";
import "./BannerImage.css";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
export default function BannerImage({ data }) {
  return (
    <div className="Banner-Image">
      <div className="Banner-Text">
        <h1
          style={{
            backgroundImage:
              "linear-gradient(180deg, #011b32 20%, #009ffd 100%)",
            padding: "1%",
            borderRadius: "10px",
          }}
        >
          {data.title}
        </h1>
        {/* <h6
          style={{
            backgroundImage:
              "linear-gradient(180deg, #011b32 20%, #009ffd 100%)",
            padding: "1%",
            borderRadius: "10px",
            fontWeight: "bold",
            display:
              data.title == "FAQ's" || data.title == "Services" ? "flex" : "",
            justifyContent:
              data.title == "Faq" || data.title == "Services" ? "center" : "",
            alignItems:
              data.title == "Faq" || data.title == "Services" ? "center" : "",
            gap: "10px",
          }}
        >
          <Link to="/">Home </Link>
          <MdKeyboardArrowRight />
          <span> {data.route}</span>
        </h6> */}
      </div>
    </div>
  );
}
