import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Testimonial.css";
import chetan from "../images/chetan2.jpg";
import raheem from "../images/raheem2.jpg";
import {  BsTwitter, BsTelephoneFill } from "react-icons/bs";
import { BiLogoLinkedin } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
const Review = () => {
  const [reviews, setReviews] = useState([]);
  const [index, setIndex] = useState(0);
  const navigate=useNavigate()
  useEffect(() => {
    // axios
    //   .get("https://azure-hen-cap.cyclic.app/testimonial")
    //   .then((res) => setReviews(res.data))
    //   .catch((err) => console.log(err));
    setReviews([
      {
        id: 1,
        name: "CA Sai Chethan Reddy",
        job: "",
        image: chetan,
        text: "Renowned for expertise in internal audit, tax audit, GST. Proven track record in identifying inefficiencies, mitigating risks, optimizing tax strategies, adhering to regulations, and delivering personalized solutions.",
        email: "cachethanreddy@gmail.com",
        linkedin: "https://www.linkedin.com/in/ca-chethan-reddy-65a76b247",
        phone: "9701449349",
        twitter: "https://twitter.com/chethanreddy07",
      },
      {
        id: 1,
        name: "CA Patan Raheem",
        job: "",
        image: raheem,
        text: "Renowned for excellence in tax audit, GST, and insolvency proceedings, ensure compliance, identify tax-saving opportunities, and offer comprehensive guidance. Proficient in providing practical solutions.",
        email: "capatanraheem@mail.ca.in",
        linkedin: "https://www.linkedin.com/in/ca-patan-raheem-843b0a165",
        phone: "8919559216",
        twitter: "https://twitter.com/raheempatan99",
      },
    ]);
  }, []);

  if (reviews.length === 0) {
    return <div>Loading...</div>;
  }

  const Chetan = reviews[0];
  const Raheem = reviews[1];

  return (
    <div className="team-container">
      <article className="review" data-aos="fade-up" onClick={()=>navigate("/aboutus/1")}>
        <div className="img-container">
          <img src={Chetan.image} alt={Chetan.name} className="person-img" />
        </div>
        <h4 className="author">{Chetan.name}</h4>
        <p className="job">{Chetan.job}</p>
        <p className="info">{Chetan.text}</p>
        <div className="button-container">
          <div className="share">
            <a href={`mailto:${Chetan.email}`}>
              <HiOutlineMail />
            </a>
            <a href={Chetan.linkedin}>
              <BiLogoLinkedin />
            </a>
            <a href={Chetan.twitter}>
              <BsTwitter />
            </a>
            <a href={`tel:${Chetan.phone}`}>
              <BsTelephoneFill />
            </a>
          </div>
        </div>
      </article>

      <article className="review" data-aos="fade-up" onClick={()=>navigate("/aboutus/2")}>
        <div className="img-container">
          <img src={Raheem.image} alt={Raheem.name} className="person-img" />
        </div>
        <h4 className="author">{Raheem.name}</h4>
        <p className="job">{Raheem.job}</p>
        <p className="info">{Raheem.text}</p>
        <div className="button-container">
          <div className="share">
            <a href={`mailto:${Raheem.email}`}>
              <HiOutlineMail />
            </a>
            <a href={Raheem.linkedin}>
              <BiLogoLinkedin />
            </a>
            <a href={Raheem.twitter}>
              <BsTwitter />
            </a>
            <a href={`tel:${Raheem.phone}`}>
              <BsTelephoneFill />
            </a>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Review;
