import React from "react";
import "./Faq.css";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  MinusIcon,
  AddIcon,
  ChakraProvider,
} from "@chakra-ui/react";
export default function Faq() {
  return (
    <div id="faq-div" style={{height: "fit-content", borderRadius: "5px" ,width:"100%"}}>
      <h1>Spread</h1>
      <ChakraProvider>
        <Accordion allowMultiple >
          <AccordionItem  className="text-hover">
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  How does the virus spread?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="justify">
              The virus that causes COVID-19 is thought to spread mainly from
              person to person, mainly through respiratory droplets produced
              when an infected person coughs, sneezes, or talks. These droplets
              can land in the mouths or noses of people who are nearby or
              possibly be inhaled into the lungs. Spread is more likely when
              people are in close contact with one another (within about 6
              feet). COVID-19 seems to be spreading easily and sustainably in
              the community (“community spread”) in many affected geographic
              areas. Community spread means people have been infected with the
              virus in an area, including some who are not sure how or where
              they became infected.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem className="text-hover">
            <h2>
              <AccordionButton >
                <Box as="span" flex="1" textAlign="left">
                  Will warm weather stop the outbreak of COVID-19?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="justify">
              It is not yet known whether weather and temperature affect the
              spread of COVID-19. Some other viruses, like those that cause the
              common cold and flu, spread more during cold weather months but
              that does not mean it is impossible to become sick with these
              viruses during other months. There is much more to learn about the
              transmissibility, severity, and other features associated with
              COVID-19 and investigations are ongoing.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </ChakraProvider>
    </div>
  );
}
