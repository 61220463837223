import "./App.css"
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import AllPages from './AllPages/AllPages';
import { useEffect, useState } from "react";
import Loader from "./Loader/Loader";
import { useLocation } from "react-router-dom";


function App() {
  const [loader, setLoader] = useState(true)
  const location = useLocation().pathname.split("/")[1];
  const isClient = location !== "admin" && location !== "Admin";

  setTimeout(() => {
    setLoader(false)
  }, 1998)
  if (loader) {
    return <Loader />
  }
  return (
    <div className="App">
       {isClient && <Navbar />}
       <AllPages />
      {isClient && <div style={{backgroundColor:'#009efb'}}><Footer /></div>}
      
      
      
    </div>
  );
}

export default App;