import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import log from "./logo-no-background.png";
export default function Navbar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [mobDrop, setMobDrop] = useState(false);
  const [drop, setDrop] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 200,
      duration: 1000,
      mirror: true,
      once: true,
      anchorPlacement: "top-bottom",
      easing: "ease",
    });
  }, []);

  const handleResourcesHover = (isHovering) => {
    setDrop(isHovering);
  };
  const navigate = useNavigate();
  return (
    <div className="main-header">
      <div className="main-header-top">
        <Link to={"/"}>
          <img src={log} alt="PRCR" />
        </Link>
        <div className="col-media-body">
          <div className="topheader-text">
            <p>
              <strong>
                A Young and Dynamic Consulting Firm
                <br />
                <span onClick={() => navigate("/auditAndCompliance/all")}>
                  {" "}
                  Virtual CFO{" "}
                </span>
                |
                <span onClick={() => navigate(`/auditAndCompliance/2`)}>
                  {" "}
                  Audit and Assurance{" "}
                </span>
                |
                <span onClick={() => navigate(`/auditAndCompliance/3`)}>
                  {" "}
                  Taxation{" "}
                </span>
                |
                <span onClick={() => navigate(`/auditAndCompliance/4`)}>
                  {" "}
                  Management Consulting{" "}
                </span>
                |
                <span onClick={() => navigate("/auditAndCompliance/5")}>
                  {" "}
                  Corporate Finance and Advisory{" "}
                </span>
                <br />|
                <span onClick={() => navigate("/auditAndCompliance/6")}>
                  {" "}
                  Insolvency and Bankruptcy{" "}
                </span>
                |
                <span onClick={() => navigate("/auditAndCompliance/7")}>
                  {" "}
                  Financial and Investment Analysis{" "}
                </span>
                |
                <span onClick={() => navigate("/auditAndCompliance/8")}>
                  {" "}
                  Education and Training
                </span>
              </strong>
            </p>
          </div>
        </div>
        <div className="menu-icon" onClick={toggleSidebar}>
          <div className={`bar ${sidebarOpen ? "open" : ""}`}></div>
          <div className={`bar ${sidebarOpen ? "open" : ""}`}></div>
          <div className={`bar ${sidebarOpen ? "open" : ""}`}></div>
        </div>
      </div>
      <hr></hr>

      <div className={`main-header-bottom ${sidebarOpen ? "open" : "close"}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="header-mainnav">
                <div id="site-navigation" className="main-navigation fleft">
                  <ul id="primary-menu" className="menu">
                    <li className="menu-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/aboutus/all">About Us</Link>
                    </li>
                    {/* <li className="menu-item current-menu-item">
                      <Link to="/">Tech Enabled Services</Link>
                    </li> */}
                    <li className="menu-item">
                      <Link to="auditAndCompliance/0">Services</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/view-and-voice">Views And Voice</Link>
                    </li>
                    <li
                      className="menu-item menu-item-has-children"
                      onMouseEnter={() => handleResourcesHover(true)}
                      onMouseLeave={() => handleResourcesHover(false)}
                      style={{ zIndex: "1" }}
                    >
                      <Link to="/resources/Ebooks">Resources</Link>
                      <AnimatePresence>
                        {drop && (
                          <motion.ul
                            initial={{ height: "0px" }}
                            animate={{ height: "fit-content" }}
                            exit={{ height: "0px" }}
                            transition={{
                              type: "just",
                              stiffness: 100,
                              duration: 0.5,
                            }}
                            className="sub-menu"
                            style={{
                              padding: "0px",
                              backgroundColor: "#3467a3",
                              backgroundImage:
                                "linear-gradient(180deg, #011b32 30%, #009ffd 100%)",
                              // border: "1px solid white",
                              // color: "white",
                            }}
                          >
                            <li className="menu-item">
                              <Link to="/resources/Ebooks">Ebooks</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/resources/FAQ's">FAQ's</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/resources/Guides">Guides</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/resources/Blog">Blog</Link>
                            </li>
                            <li className="menu-item" style={{ border: "0px" }}>
                              <Link to="/resources/ExternalLink">
                                {" "}
                                Useful Links
                              </Link>
                            </li>
                          </motion.ul>
                        )}
                      </AnimatePresence>
                    </li>
                    <li className="menu-item">
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                <AnimatePresence>
                  {sidebarOpen && (
                    <motion.ul
                      initial={{ height: 0 }}
                      animate={{ height: "fit-content" }}
                      exit={{ height: 0 }}
                      transition={{
                        type: "just",
                        stiffness: 100,
                        duration: 0.5,
                      }}
                      //  id="primary-menu"
                      className="mobile"
                    >
                      <li
                        className="menu-item"
                        onClick={() => {
                          navigate("/");
                          toggleSidebar();
                        }}
                      >
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="menu-item"
                        onClick={() => {
                          navigate("/aboutus/all");
                          toggleSidebar();
                        }}
                      >
                        <Link to="/aboutus/all">About Us</Link>
                      </li>

                      <li
                        className="menu-item"
                        onClick={() => {
                          navigate("/auditAndCompliance/1");
                          toggleSidebar();
                        }}
                      >
                        <Link to="/auditAndCompliance/1">Services</Link>
                      </li>
                      <li
                        className="menu-item"
                        onClick={() => {
                          navigate("/view-and-voice");
                          toggleSidebar();
                        }}
                      >
                        <Link to="/view-and-voice">Views And Voice</Link>
                      </li>
                      <li
                        className="menu-item menu-item-has-children"
                        onMouseEnter={() => handleResourcesHover(true)}
                        onMouseLeave={() => handleResourcesHover(false)}
                      >
                        <Link
                          to="#"
                          onClick={() => setMobDrop(!mobDrop)}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          Resources{" "}
                          {mobDrop ? (
                            <IoMdArrowDropup />
                          ) : (
                            <IoMdArrowDropdown />
                          )}
                        </Link>
                        <AnimatePresence>
                          {mobDrop && (
                            <motion.ul
                              initial={{ height: 0 }}
                              animate={{ height: "fit-content" }}
                              exit={{ height: 0 }}
                              transition={{
                                type: "just",
                                stiffness: 100,
                                duration: 0.5,
                              }}
                              // className="sub-menu"
                              style={{ padding: "0px", overflow: "hidden" }}
                            >
                              <li
                                className="menu-item"
                                onClick={() => {
                                  navigate("/resources/Ebooks");
                                  toggleSidebar();
                                  setMobDrop(!mobDrop);
                                }}
                              >
                                <Link
                                  to="/resources/Ebooks"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "initial",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  - Ebooks
                                </Link>
                              </li>
                              <li
                                className="menu-item"
                                onClick={() => {
                                  navigate("/resources/FAQ's");
                                  toggleSidebar();
                                  setMobDrop(!mobDrop);
                                }}
                              >
                                <Link
                                  to="/resources/FAQ's"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "initial",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  - FAQ's
                                </Link>
                              </li>
                              <li
                                className="menu-item"
                                onClick={() => {
                                  navigate("/resources/Guides");
                                  toggleSidebar();
                                  setMobDrop(!mobDrop);
                                }}
                              >
                                <Link
                                  to="/resources/Guides"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "initial",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  - Guides
                                </Link>
                              </li>
                              <li
                                className="menu-item"
                                style={{ border: "0px" }}
                                onClick={() => {
                                  navigate("/resources/Blog");
                                  toggleSidebar();
                                  setMobDrop(!mobDrop);
                                }}
                              >
                                <Link
                                  to="/resources/Blog"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "initial",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  - Blog
                                </Link>
                              </li>

                              <li
                                className="menu-item"
                                style={{ border: "0px" }}
                                onClick={() => {
                                  navigate("/resources/ExternalLink");
                                  toggleSidebar();
                                  setMobDrop(!mobDrop);
                                }}
                              >
                                <Link
                                  to="/resources/ExternalLink"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "initial",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  - Useful Links
                                </Link>
                              </li>
                            </motion.ul>
                          )}
                        </AnimatePresence>
                      </li>
                      <li
                        className="menu-item"
                        onClick={() => {
                          navigate("/contact");
                          toggleSidebar();
                        }}
                      >
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </motion.ul>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
