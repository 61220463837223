import React, { useEffect, useState } from "react";
import "./AuditAndCompliance.css";
import SpecialisedServicesSection from "./SpecialisedServicesSection";
import BannerImage from "../BanneImage/BannerImage";
import axios from "axios";
import { useParams } from "react-router-dom";
import img1 from "./download1.png";
import img2 from "./download2.png";
import img3 from "./download3.png";
import img4 from "./download4.png";
import img5 from "./download5.png";
import img6 from "./download6.png";
import img7 from "./download7.png";
import img8 from "./download8.png";
export default function AuditAndCompliance() {
  // const [specialisedServicesData, setSpecialisedServicesData] = useState("");

  const specialisedServicesData = [
    {
      _id: "64c111f1a50fcd7e7ac65bdf",
      title: "Financial Accounting and Reporting",
      services: [
        "Preparation and presentation of financial statements in accordance with applicable accounting standards.",
        "Accounting system design, implementation, and maintenance.",
        "Financial statement analysis and interpretation.",
        "Consolidation of financial statements for group companies.",
        "Preparation of management reports and financial forecasts.",
      ],
      imageSrc: img5,
    },
    {
      _id: "64c111f1a50fcd7e7ac65be0",
      title: "Audit and Assurance",
      services: [
        "External audit of financial statements to express an opinion on their fairness and compliance with accounting standards.",
        "Internal audit to assess and improve the effectiveness of an organization's internal controls and risk management processes.",
        "Due diligence reviews for mergers, acquisitions, and investments.",
        "Compliance audits to ensure adherence to laws, regulations, and internal policies.",
        "Special audits, such as forensic audits, investigative audits, and performance audits.",
      ],
      imageSrc: img2,
    },
    {
      _id: "64d4f611f122f12d17ab7a86",
      title: "Startup advisory",
      services: [
        "Strategic Financial Planning for Startup Success",
        "Navigating Fundraising Challenges with Expert Guidance",
        "Compliance Assurance: Keeping Startups Regulation-Ready",
        "Crafting Actionable Business Plans for Growth",
        "Maximizing Profits Through Financial Optimization",
      ],
      imageSrc: img7,
    },
    {
      _id: "64c111f1a50fcd7e7ac65be1",
      title: "Taxation",
      services: [
        "Tax planning and advisory services to minimize tax liabilities while ensuring compliance with tax laws.",
        "Preparation and filing of tax returns for individuals, businesses, and other entities.",
        "Handling tax assessments, appeals, and representing clients before tax authorities.",
        "Providing guidance on tax implications of business transactions, investments, and restructuring.",
        "Transfer pricing analysis and documentation.",
      ],
      imageSrc: img6,
    },
    {
      _id: "64c111f1a50fcd7e7ac65be2",
      title: "Management Consulting",
      services: [
        "Financial and strategic planning, including budgeting and forecasting.",
        "Business valuations, feasibility studies, and investment analysis.",
        "Cost analysis, cost control, and profitability improvement.",
        "Risk assessment and management.",
        "Performance measurement and benchmarking.",
        "Design and implementation of internal control systems.",
      ],
      imageSrc: img4,
    },
    {
      _id: "64c111f1a50fcd7e7ac65be3",
      title: "Corporate Finance and Advisory",
      services: [
        "Business restructuring, mergers, acquisitions, and divestitures.",
        "Financial due diligence and valuation services.",
        "Financial modeling and project evaluation.",
        "Advice on corporate governance and compliance.",
      ],
      imageSrc: img3,
    },
    {
      _id: "64c111f1a50fcd7e7ac65be4",
      title: "Insolvency and Bankruptcy",
      services: [
        "Insolvency & liquidation proceedings.",
        "Business restructuring and turnaround management.",
        "Forensic investigations related to insolvency matters.",
        "Providing guidance on bankruptcy laws and proceedings.",
      ],
      imageSrc: img8,
    },
    {
      _id: "64c111f1a50fcd7e7ac65be5",
      title: "Financial and Investment Analysis",
      services: [
        "Investment advisory services.",
        "Portfolio management and asset allocation.",
        "Financial market research and analysis.",
        "Risk assessment and management.",
      ],
      imageSrc: img7,
    },
    {
      _id: "64c111f1a50fcd7e7ac65be6",
      title: "Education and Training",
      services: [
        "Seminars.",
        "Workshops.",
        "Training sessions.",
        "Accounting, Finance, and Taxation.",
      ],
      imageSrc: img1,
    },
  ];

  const { id } = useParams();

  setTimeout(() => {
    const element = document.getElementById(`service_${id}`);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, 500);
  // useEffect(() => {
  //   // Scroll to the clicked service when the component mounts
  //

  // }, [id]);
  return (
    <>
      <BannerImage data={{ title: "Services", route: "Services" }} />
      <section className="audit-section">
        <div className="career-box">
          {/* <div class="section-head">
            <h2 class="section-title" style={{ textAlign: 'center' }}>Services</h2>
          </div> */}
          <br></br>
          <div class="wpb_text_column wpb_content_element ">
            <div class="wpb_wrapper">
              <p>
                At PRCR Chartered Accountants, we offer a wide range of
                services, including Financial Accounting and Reporting, Audit
                and Assurance, Taxation, Management Consulting, Corporate
                Finance and Advisory, Insolvency and Bankruptcy, Financial and
                Investment Analysis, and Education and Training. Trust us as
                your dedicated partner to meet all your financial needs with
                excellence and expertise.
              </p>
            </div>
          </div>

          {/* Render each specialised services section */}
          {specialisedServicesData &&
            specialisedServicesData.map((serviceData, index) => (
              <div
                className="title-heading"
                key={index}
                id={`service_${index + 1}`}
              >
                <h5>{serviceData.title}</h5>
                <SpecialisedServicesSection
                  imageSrc={serviceData.imageSrc}
                  services={serviceData.services}
                  title={serviceData.title}
                  id={serviceData._id}
                  index={index}
                />
              </div>
            ))}
        </div>
      </section>
    </>
  );
}
