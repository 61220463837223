import React from "react";
import ProductCard from "./ProductCard";
import "./product.css";
export default function Products() {
  const data = [
    {
      h1: "Virtual CFO",
      p: "Expertly preparing and presenting financial statements, designing accounting systems, and offering comprehensive financial analysis to support informed decision-making for businesses.",
    },
    {
      h1: "Audit and Assurance",
      p: "Ensuring financial integrity through external and internal audits, compliance checks, due diligence reviews, and specialized audits for robust risk management.",
    },
    {
      h1: "Taxation",
      p: "Providing strategic tax planning, filing tax returns, handling assessments, and offering expert guidance on tax implications for businesses and individuals.",
    },
    {
      h1: "Management Consulting",
      p: "Offering tailored financial and strategic planning, cost analysis, risk management, and the design of effective internal control systems.",
    },
    {
      h1: "Corporate Finance and Advisory",
      p: "Guiding businesses through mergers, acquisitions, divestitures, and financial due diligence, with a focus on corporate governance and compliance.",
    },
    {
      h1: "Insolvency and Bankruptcy",
      p: "Assisting with insolvency proceedings, restructuring, and forensic investigations related to insolvency matters, along with expert guidance on bankruptcy laws.",
    },
    {
      h1: "Financial and Investment Analysis",
      p: "Providing investment advisory services, portfolio management, financial market research, and risk assessment to optimize investment decisions.",
    },
    {
      h1: "Education and Training",
      p: "Conducting seminars, workshops, and training sessions on accounting, finance, and taxation to empower individuals and businesses with essential financial knowledge.",
    },
  ];
  return (
    <div className="product">
      <h1 style={{ textAlign: "start" }}>Our Offerings</h1>
      <br></br>
      <div className="card-main">
        {data && data.map((el, index) => <ProductCard data={el} index={index} />)}
      </div>
    </div>
  );
}
