import React, { useState } from 'react';
import { Card, Box, Heading, Button, CardHeader, CardBody, CardFooter, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Grid, FormControl, FormLabel, Image, ModalFooter, Select, Input, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Textarea } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function EventCard({ event, Update, Delete }) {
  const [update, setUpdate] = useState(false)
  const [isDeleteAlertOpenEvent, setIsDeleteAlertOpenEvent] = useState(false)
  const {
    isOpen: isOpenEditEvent1,
    onOpen: onOpenEditEvent1,
    onClose: onCloseEditEvent1,
  } = useDisclosure();

  const {
    isOpen: isOpenModal2,
    onOpen: onOpenModal2,
    onClose: onCloseModal2,
  } = useDisclosure();
  const initialRef = React.useRef(null);
  const [formData, setFormData] = useState({});
  const finalRef = React.useRef(null);


  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name === "appointmentDate") {
      const [year, month, day] = value.split("-");
      const formattedDate = `${day}/${month}/${year}`;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formattedDate,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value,
    // }));
  };
  // console.log("form",formData)
  return (
    <>
      <Card boxShadow='dark-lg' rounded='md' bg='white'>
        <CardHeader gap={5} display="flex" alignItems='baseline' justifyContent='space-between'>
          <Heading size='md'>Enquiry Details</Heading>
          <Box gap='2' display="flex" alignItems='baseline' justifyContent='space-between'>
            <Button p={2} onClick={onOpenEditEvent1} backgroundColor='#92d592' fontSize='10px'
              _hover={{

                color: "orange",
                bg: 'transparent'
              } // Replace with your desired text color
              }>Edit</Button>
            <Button p={2} onClick={() => setIsDeleteAlertOpenEvent(true)} backgroundColor='red' color='white' fontSize='10px' _hover={{

              color: "orange",
              bg: 'transparent'
            } // Replace with your desired text color
            }>Delete</Button>
          </Box>
        </CardHeader>
        <CardBody>
          <Box gap={2} display="flex" alignItems='baseline'>
            <span><strong style={{ marginRight: '10px' }}>Name:</strong> {event.full_name}</span>
          </Box>
          <Box gap={2} display="flex" alignItems='baseline'>
            <span><strong style={{ marginRight: '10px' }}>Mobile:</strong> {event.phone}</span>
          </Box>
          <Box gap={2} display="flex" alignItems='baseline'>
            <span><strong style={{ marginRight: '10px' }}>Enquiry :</strong> {event.eventName ? event.eventName : "Special Event"}</span>
          </Box>

          <Box gap={2} display="flex" alignItems="baseline">
            <span>
              <strong style={{ marginRight: "10px" }}>Enquiry Date:</strong>{" "}
              {event.appointmentDate ? event.appointmentDate : "Any Date"}
            </span>
          </Box>
          {/* <Box gap={2} display="flex" alignItems='baseline'>
            <span><strong style={{ marginRight: '10px' }}>Date:</strong> {event.eventDate}</span>
          </Box>
          <Box gap={2} display="flex" alignItems='baseline'>
            <span><strong style={{ marginRight: '10px' }}>Time:</strong> {event.eventTime}</span>
          </Box> */}
          <Box gap={2} display="flex" alignItems='baseline'>
            <span>
              <strong color='black' style={{ marginRight: '10px' }}>Status :</strong>
              <span style={event.eventStatus === "Pending" ? { color: "red" } : { color: 'green' }}>{event.eventStatus}</span>
            </span>
          </Box>
          <Box gap={2} display="flex" alignItems='baseline'>
            <span>
              <strong color='black' style={{ marginRight: '10px' }}>Payment :</strong>
              <span style={{ color: event.paymentStatus ? "green" : "red" }}>{event.paymentStatus ? "Paid" : "Not Paid"}</span>
            </span>
          </Box>



        </CardBody>
        <CardFooter spacing={5} justifyContent="space-between">


          <Button onClick={onOpenModal2} color="black" backgroundColor='gold' fontSize='13px'
            _hover={{

              color: "orange",
              bg: 'transparent'
            }}
          >View Enquiry </Button>


          <Link to={`/admin/usersDtails/${event.userId}`}>
            <Button color="white" backgroundColor='#803a00' fontSize='13px' _hover={{

              color: "orange",
              bg: 'transparent'
            }}>View User</Button>
          </Link>
        </CardFooter>
      </Card>


      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpenModal2}
        onClose={onCloseModal2}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            style={{
              background: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
              color: "white",
              textAlign: "center",
              borderRadius: '5px 5px 0 0'
            }}
          >
            Enquiry Details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Grid
              templateColumns={{ base: "1fr", md: "1fr 1fr", sm: "1fr" }}
              gap={4}
            >
              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Full Name</FormLabel>
                <Box>{`${event.full_name}`}</Box>
              </FormControl>

              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Mobile number</FormLabel>
                <Box
                  name="fname"
                  ref={initialRef}
                  placeholder="First name"
                // onChange={handleInputChange}
                // value={formData.fname}
                >
                  {event.phone}
                </Box>
              </FormControl>

              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Email</FormLabel>
                <Box
                  name="fname"
                  ref={initialRef}
                  placeholder="First name"
                // onChange={handleInputChange}
                // value={formData.fname}
                >
                  {event.email}
                </Box>
              </FormControl>

              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Enquiry Status</FormLabel>
                <Box
                  name="fname"
                  ref={initialRef}
                  placeholder="First name"
                // onChange={handleInputChange}
                // value={formData.fname}
                >
                  {event.eventStatus}
                </Box>
              </FormControl>



              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Enquiry Date</FormLabel>
                <Box

                >
                  {event.appointmentDate ? event.appointmentDate : "Any Date"}
                </Box>
              </FormControl>


              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Amount</FormLabel>
                <Box

                >
                  {event.ammount}
                </Box>
              </FormControl>
              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Payment Status</FormLabel>
                <Box

                >
                  {event.paymentStatus ? <span style={{ color: "green" }}>Paid</span> : <span style={{ color: "red" }}>Not Paid</span>}
                </Box>
              </FormControl>





              <FormControl gridColumn={{ base: "1 / span 2", md: "1 / span 2" }} width="100%">
                <FormLabel>Message</FormLabel>
                <Box

                >
                  {event.message ? event.message : "Null"}
                </Box>
              </FormControl>



            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpenEditEvent1}
        onClose={onCloseEditEvent1}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            style={{
              background: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
              color: "white",
              textAlign: "center",
              borderRadius: '5px 5px 0 0'
            }}
          >
            Edit Enquiry
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Grid
              templateColumns={{ base: "1fr", md: "1fr 1fr", sm: "1fr" }}
              gap={4}
            >
              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Full name</FormLabel>
                <Input
                  name="full_name"
                  ref={initialRef}
                  placeholder={event.full_name}
                  onChange={handleInputChange}
                  value={formData.full_name}
                />
              </FormControl>




              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  placeholder={event.email}
                  onChange={handleInputChange}
                  value={formData.email}
                />
              </FormControl>
              <FormControl
                gridColumn={{
                  base: "1 / span 2",
                  md: "auto",
                }}
              >
                <FormLabel>Enquiry Date</FormLabel>
                <Input
                  name="appointmentDate"
                  type="date"
                  border="1px solid black"
                  placeholder={formData.appointmentDate}
                  min={new Date().toISOString().slice(0, 10)}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Enquiry Status</FormLabel>
                <Select
                  name="eventStatus"
                  value={formData.eventStatus || event.eventStatus}
                  onChange={handleInputChange}
                >
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                </Select>
              </FormControl>

              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Payment</FormLabel>
                <Select
                  name="paymentStatus"
                  value={formData.paymentStatus || event.paymentStatus}
                  onChange={handleInputChange}
                  defaultValue={event.paymentStatus ? "paid" : "notPaid"}

                >
                  <option value={false}>Not Paid</option>
                  <option value={true}>Paid</option>
                </Select>
              </FormControl>
              <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                <FormLabel>Amount</FormLabel>
                <Input
                  placeholder={event.ammount}
                  name="ammount"
                  type="number"
                  onChange={handleInputChange}
                  value={formData.ammount}
                />
              </FormControl>







            </Grid>
          </ModalBody>

          <ModalFooter>
            <Button _hover={{

              color: "orange",
              bg: 'transparent'
            }}
              color="white"
              gb="brown"
              mr={3}
              onClick={() => {
                Update(formData, event._id)
                onCloseEditEvent1();
                console.log("form", formData)
                setFormData({});
              }}
              backgroundColor="gold"
            >
              Save
            </Button>
            <Button _hover={{

              color: "orange",
              bg: 'transparent'
            }} onClick={onCloseEditEvent1}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteAlertOpenEvent}
        leastDestructiveRef={finalRef}
        onClose={() => setIsDeleteAlertOpenEvent(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this Enquiry?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={finalRef}
                onClick={() => setIsDeleteAlertOpenEvent(false)}
              >
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => {
                setIsDeleteAlertOpenEvent(false)
                Delete(event._id)
              }} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default EventCard;
