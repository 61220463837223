import React, { useState } from 'react';
import './AdminNav.css';
import { Link, useNavigate } from 'react-router-dom';
function AdminNav() {
    const [data,setData]=useState(false)
    const navigate=useNavigate()
    return (
        <nav className="Adminnavbar">
            <div className="navbar-container container">
                <input type="checkbox" name="" id="" />
                <div className="hamburger-lines">
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                    <span className="line line3"></span>
                </div>
                <ul className="menu-items">
                    <li><Link to="/admin/users">Users</Link></li>
                    {/* <li><Link to="/admin/appointments">Appoinments</Link></li> */}
                    {/* <li><Link to="/admin/astro">Astro</Link></li> */}
                    <li><Link to="/admin/Services">Enquiries</Link></li>
                    {/* <li><Link to="/admin/AddService">Add Main Services</Link></li>
                    <li><Link to="/admin/AddSubService">Add Sub Services</Link></li> */}
                    <li><Link to="/admin/allservice">Services</Link></li>
                    <li className='logOut'><button onClick={()=>{
                    localStorage.removeItem("AdminToken")
                    setData(!data)
                    navigate("/admin/home")
                }}>Log Out</button></li>

                </ul>
                {localStorage.getItem("AdminToken") ? <button onClick={()=>{
                    localStorage.removeItem("AdminToken")
                    setData(!data)
                    navigate("/admin/home")
                }}>Log Out</button> : <Link to="/admin/home"><h3 className="logo">PRCR</h3></Link>}
            </div>
        </nav>
    );
}

export default AdminNav;
