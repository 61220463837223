// Loader.js

import React from 'react';
import './Loader.css';
import logo from '../Navbar/logo.gif'
const Loader = () => {
  return (
    <div style={{ height: "100vh", display: 'flex',backgroundColor:" #011b32" }}>
      {/* <div className="loader" style={{ margin: 'auto' }}>
        <div className="loader__bar"></div>
        <div className="loader__bar"></div>
        <div className="loader__bar"></div>
        <div className="loader__bar"></div>
        <div className="loader__bar"></div>
        <div className="loader__ball"></div>
      </div> */}
      <img src={logo}  style={{width:'30%',margin:'auto'}}/>
    </div>
  );
};

export default Loader;
