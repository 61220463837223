import React, { useState } from "react";
import "./Viwe.css";
import BannerImage from "../BanneImage/BannerImage";
import logo from ".././4393.jpg";
import { BsYoutube } from "react-icons/bs";
export default function Viwe() {
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const onFilter = (data) => {
    setFilter(data);
    setCurrentPage(1);
  };

  const data = [
    {
      img: logo,
      title: "CII",
      cetegory: "It",
    },
    {
      img: logo,
      title: "CII",
      cetegory: "It",
    },
    {
      img: logo,
      title: "Future",
      cetegory: "science",
    },
    {
      img: logo,
      title: "Future",
      cetegory: "science",
    },
    {
      img: logo,
      title: "CA",
      cetegory: "CA",
    },
    {
      img: logo,
      title: "CA",
      cetegory: "CA",
    },
  ];
  const filteredData = data.filter(
    (el) => filter === "All" || el.cetegory === filter
  );

  // Calculate the index range of items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Generate pagination controls
  const renderPagination = () => {
    const pageNumbers = Math.ceil(filteredData.length / itemsPerPage);
    const pagination = [];

    for (let i = 1; i <= pageNumbers; i++) {
      pagination.push(
        <li
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </li>
      );
    }
    return pagination;
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <BannerImage data={{ title: "Views & Voice", route: "Views & Voice" }} />
      <div className="View-main">
        <div className="view">
          <div className="view-content">
            <div className="view-card">
              {currentItems &&currentItems.map((el, index) => (
                <div key={index} data-aos="fade-up">
                  <div className="img">
                    <img src={el.img} alt="Session" />
                    <BsYoutube className="youtube" />
                  </div>
                  <div className="title-text">
                    <h5>{el.title}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="view-filter">
            <div>
              <h3>Views & Voice</h3>
              <div>
                <p onClick={() => onFilter("All")}>All</p>
                <p onClick={() => onFilter("It")}>
                  At Confederation on Indian Industries (CII)
                </p>
                <p onClick={() => onFilter("science")}>
                  Future of Profession & Industry 4.0
                </p>
                <p onClick={() => onFilter("WebSeries")}>
                  Web series on Data Analytics
                </p>
                <p onClick={() => onFilter("Analytics")}>Analytics and Audit</p>
                <p onClick={() => onFilter("Blockchain")}>
                  Blockchain & Finance
                </p>
                <p onClick={() => onFilter("CA")}>Digitising CA Practices</p>
                <p onClick={() => onFilter("Tally")}>Tally & Productivity</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#009efb",
          padding: "20px",
        }}
      >
        <ul className="pagination">
          <li className="prev" onClick={handlePrevPage}>
            Previous
          </li>
          {renderPagination()}
          <li className="next" onClick={handleNextPage}>
            Next
          </li>
        </ul>
      </div>
    </>
  );
}
