import React, { useEffect, useState } from "react";
import Swiper from "swiper";
import { HiLightBulb } from "react-icons/hi";
import "./Blog.css";
import img1 from "./img1.jpg";
import img2 from "./img2.jpg";
import img3 from "./img3.jpg";
import img4 from "./img4.jpg";
const blogSlides = [
  {
    title: "About Us",
    text: "PRCR Chartered Accountants is a leading firm providing exceptional audit services to businesses of all sizes. With a relentless commitment to excellence, we have established ourselves as a trusted partner for organizations seeking accurate and insightful financial assessments. Our team of highly skilled professionals possesses extensive experience in conducting various audit engagements, including external financial statement audits, internal audits, compliance audits, due diligence reviews, forensic audits, and performance audits.",
    image: img1,
  },
  {
    title: "Why Choose PRCR?",
    text: "PRCR Chartered Accountants is your trusted partner for exceptional audit services. With an unwavering commitment to excellence, we tailor solutions to your unique needs. Our experienced professionals bring industry expertise and insights to navigate complex financial landscapes. Beyond numbers, we prioritize building long-term relationships as trusted advisors for sustainable growth. Leveraging advanced technologies and innovative methodologies, we deliver accurate and timely results, ensuring client satisfaction with a proven track record of tangible value. Our global reach ensures compliance in an interconnected business environment. Choose PRCR Chartered Accountants for clarity, insight, and peace of mind, and experience our dedication to your success.",
    image: img2,
  },
  {
    title: "Our Experience",
    text: "PRCR Chartered Accountants has developed a profound expertise in handling a diverse array of audit engagements. Our team of highly skilled professionals possesses a deep understanding of auditing standards, regulations, and industry best practices. Throughout our journey, we have successfully executed numerous external financial statement audits, internal audits, and compliance audits for organizations spanning various sectors.We approach each audit engagement with meticulous attention to detail, employing robust methodologies and advanced tools to ensure the utmost accuracy, reliability, and integrity of our audit processes. ",
    image: img3,
  },
  {
    title: "Belief and Vision",
    text: "At PRCR Chartered Accountants, our belief centers on trust, integrity, and professionalism. We maintain the highest ethical standards to ensure the accuracy and reliability of financial information, delivering transparent and unbiased audit services that inspire confidence in our clients and stakeholders. Through continuous learning and staying abreast of evolving regulations and industry trends, we offer exceptional value. With a client-centric approach, open communication, and tailored solutions, we forge long-term relationships. Our vision is to be the industry's premier provider, setting the standard for excellence by delivering accurate, reliable, and insightful audits that guide clients towards their financial goals. Fostering a culture of improvement, innovation, and professional growth, we aim to positively impact our clients, employees, and the broader business community.",
    image: img4,
  },
  // Add more objects for additional slides
];

const BlogSlider = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [lastSlideIndex, setLastSlideIndex] = useState(0);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    // Add event listener to update isMobile on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const swiper = new Swiper(".blog-slider", {
      spaceBetween: 30,
      effect: "fade",
    });
    setLastSlideIndex(blogSlides.length - 1);
  }, []);

  return (
    <div className="blog_container">
      <br></br>
      <h1
        style={{
          textAlign: "center",
          color: "white",
          // padding: "10px 0px 10px",
        }}
      >
        Who We Are
        {/* <HiLightBulb style={{ padding: "0px 0px 10px 0px" }} /> */}
      </h1>
      {blogSlides.map((slide, index) => (
        <div
          className={`blog-slider ${
            index === lastSlideIndex ? "last-blog-slider" : ""
          }`}
          key={index}
          data-aos="fade-up"
        >
          <div className="blog-slider__wrp swiper-wrapper">
            <div className="blog-slider__item swiper-slide">
              {isMobile ? (
                <>
                  <div className="blog-slider__img">
                    <img src={slide.image} alt="" />
                  </div>
                  <div className="blog-slider__content">
                    <div
                      className="blog-slider__title"
                      data-aos="fade-up"
                      style={{ fontSize: "25px" }}
                    >
                      {slide.title}
                    </div>
                    <div
                      className="blog-slider__text"
                      data-aos="fade-up"
                      style={{ fontSize: "14px" }}
                    >
                      {slide.text}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {index % 2 === 0 ? (
                    <>
                      <div className="blog-slider__img">
                        <img src={slide.image} alt="" />
                      </div>
                      <div className="blog-slider__content">
                        <div
                          className="blog-slider__title"
                          data-aos="fade-up"
                          style={{
                            fontSize: "250%",
                          }}
                        >
                          {slide.title}
                        </div>
                        <div
                          className="blog-slider__text"
                          data-aos="fade-up"
                          // style={{ fontSize: "17px" }}
                        >
                          {slide.text}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="blog-slider__content">
                        <div
                          className="blog-slider__title"
                          data-aos="fade-up"
                          style={{ fontSize: "250%" }}
                        >
                          {slide.title}
                        </div>
                        <div
                          className="blog-slider__text"
                          data-aos="fade-up"
                          // style={{ fontSize: "17px" }}
                        >
                          {slide.text}
                        </div>
                      </div>
                      <div className="blog-slider__img right">
                        <img src={slide.image} alt="" />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ))}
      <br></br>
      <br></br>
    </div>
  );
};

export default BlogSlider;
