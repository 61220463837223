import React, { useEffect, useState } from "react";
import {
  // Button,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Grid,
  GridItem,
  Image,
  Select,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  Box,
  Text,
  StackDivider,
  ChakraProvider,
  useToast,
  Button,
} from "@chakra-ui/react";
import AdminNav from "../AdminNav/AdminNav";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
export default function SingleEventDetails() {
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isOpen: isOpenModal2,
    onOpen: onOpenModal2,
    onClose: onCloseModal2,
  } = useDisclosure();

  const {
    isOpen: isOpenEditEvent1,
    onOpen: onOpenEditEvent1,
    onClose: onCloseEditEvent1,
  } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { id } = useParams();
  const [eventData, setEventData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formData, setFormData] = useState({});
  const [update, setUpdate] = useState(false);
  const [isDeleteAlertOpenEvent, setIsDeleteAlertOpenEvent] = useState(false);
  const [subEvent, setSubEvent] = useState("");
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name === "appointmentDate") {
      const [year, month, day] = value.split("-");
      const formattedDate = `${day}/${month}/${year}`;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formattedDate,
      }));
    } 
    else{
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
   
  };

  const handleSubmitEvent = () => {
    axios
      .patch(
        `https://fierce-worm-boot.cyclic.app/event/${selectedEvent._id}`,
        formData
      )
      .then((res) => {
        setUpdate(!update);
        onCloseEditEvent1();
        setFormData({});
        toast({
          title: "Event Update",
          description: "Your event update successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description:
            "An error occurred while submitting the form. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };
  useEffect(() => {
    axios
      .get(`https://fierce-worm-boot.cyclic.app/service/${id}`)
      .then((res) => setSubEvent(res.data))
      .catch((err) => console.log(err));

    axios
      .get(`https://fierce-worm-boot.cyclic.app/event/users/${id}`)
      .then((res) => setEventData(res.data))
      .catch((err) => console.log(err));
  }, [update]);

  const onDeleteConfirmEvent = () => {
    axios
      .delete(`https://fierce-worm-boot.cyclic.app/event/${selectedEvent._id}`)
      .then((res) => {
        setUpdate(!update);
        setIsDeleteAlertOpenEvent(false);
        toast({
          title: "Event Deleted",
          description: "Your event delete successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Perform any necessary actions after successful deletion
      })
      .catch((err) => {
        toast({
          title: "Error",
          description:
            "An error occurred while submitting the form. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const openEventModal = (event, model) => {
    setSelectedEvent(event);
    onOpenModal2();
  };

  const openEditEvent = (event) => {
    setSelectedEvent(event);
    onOpenEditEvent1();
  };

  console.log(selectedEvent);
  return (
    <ChakraProvider>
      <AdminNav />
      <Card>
        <CardHeader>
          <Heading size="xl" textAlign="center">
            {subEvent && subEvent[0].title} 
          </Heading>
          {/* <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src={subEvent && subEvent[0].image}
              alt="Caffe Latte"
            />

            <Stack>
              <CardBody>
                <Heading size="md">Short Description</Heading>

                <Text py="2">{subEvent && subEvent[0].sdescription}</Text>
              </CardBody>
            </Stack>
          </Card> */}
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Heading
                size="md"
                textTransform="uppercase"
                textAlign="center"
                p={10}
              >
                Pending Enquiries
              </Heading>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Full Name</Th>
                      <Th>Service Name</Th>
                      <Th>Enquiry Date</Th>
                      <Th>Payment</Th>
                      <Th>View Details</Th>
                      <Th>Edit</Th>
                      <Th>Delete</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {eventData &&
                      eventData.map((el) => {
                        if (el.eventStatus == "Pending") {
                          return (
                            <Tr>
                              <Td>{`${el.full_name}`}</Td>
                              <Th>{el.eventName}</Th>
                              <Td>{el.appointmentDate ? el.appointmentDate:"Any Date"}</Td>
                              <Td color={el.paymentStatus ? "green" : "red"}>
                                {el.paymentStatus ? "Paid" : "Not Paid"}
                              </Td>
                              <Td>
                                <Button
                                  onClick={() => {
                                    openEventModal(el);
                                  }}
                                  bg="orange"
                                >
                                  View
                                </Button>
                              </Td>
                              <Td
                                onClick={() => {
                                  openEditEvent(el);
                                }}
                              >
                                <Button bg="yellow">Edit</Button>
                              </Td>
                              <Th>
                                <Button
                                  onClick={() => {
                                    setSelectedEvent(el);
                                    setIsDeleteAlertOpenEvent(true);
                                  }}
                                  bg="red"
                                >
                                  Delete
                                </Button>
                              </Th>
                            </Tr>
                          );
                        }
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>

            <Box>
              <Heading
                size="md"
                textTransform="uppercase"
                textAlign="center"
                p={10}
              >
                Completed Enquiries
              </Heading>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Full Name</Th>
                      <Th>Service Name</Th>
                      <Th>Enquiry Date</Th>
                      <Th>Payment</Th>
                      <Th>View Details</Th>
                      <Th>Edit</Th>
                      <Th>Delete</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {eventData &&
                      eventData.map((el) => {
                        if (el.eventStatus == "Completed") {
                          return (
                            <Tr>
                              <Td>{`${el.full_name}`}</Td>
                              <Th>{el.eventName}</Th>
                              <Td>{el.appointmentDate?el.appointmentDate:"Any Date"}</Td>
                              <Td color={el.paymentStatus ? "green" : "red"}>
                                {el.paymentStatus ? "Paid" : "Not Paid"}
                              </Td>
                              <Td>
                                <Button
                                  onClick={() => {
                                    openEventModal(el);
                                  }}
                                  bg="orange"
                                >
                                  View
                                </Button>
                              </Td>
                              <Td>
                                <Button
                                  onClick={() => {
                                    openEditEvent(el);
                                  }}
                                  bg="yellow"
                                >
                                  Edit
                                </Button>
                              </Td>
                              <Th>
                                <Button
                                  onClick={() => {
                                    setSelectedEvent(el);
                                    setIsDeleteAlertOpenEvent(true);
                                  }}
                                  bg="red"
                                >
                                  Delete
                                </Button>
                              </Th>
                            </Tr>
                          );
                        }
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </CardBody>
      </Card>

      {/* // Events Details */}
      {selectedEvent && (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpenModal2}
          onClose={onCloseModal2}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              style={{
                background: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
                color: "white",
                textAlign: "center",
                borderRadius: "5px 5px 0 0",
              }}
            >
              Enquiry Details
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Grid
                templateColumns={{ base: "1fr", md: "1fr 1fr", sm: "1fr" }}
                gap={4}
              >
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Full Name</FormLabel>
                  <Box>{`${selectedEvent.full_name}`}</Box>
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Mobile number</FormLabel>
                  <Box>{selectedEvent.phone}</Box>
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Email</FormLabel>
                  <Box>{selectedEvent.email}</Box>
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Enquiry Status</FormLabel>
                  <Box
             
                  >
                    {selectedEvent.eventStatus}
                  </Box>
                
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Enquiry Date</FormLabel>
                  <Box>{selectedEvent.appointmentDate ? selectedEvent.appointmentDate:"Any Date"}</Box>
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Amount</FormLabel>
                  <Box>{selectedEvent.ammount}</Box>
                </FormControl>
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Payment Status</FormLabel>
                  <Box>
                    {selectedEvent.paymentStatus ? (
                      <span style={{ color: "green" }}>Paid</span>
                    ) : (
                      <span style={{ color: "red" }}>Not Paid</span>
                    )}
                  </Box>
                </FormControl>
                <FormControl
                  gridColumn={{ base: "1 / span 2", md: "1 / span 2" }}
                  width="100%"
                >
                  <FormLabel>Message</FormLabel>
                  <Box>
                    {selectedEvent.message ? selectedEvent.message : "Null"}
                  </Box>
                </FormControl>
              </Grid>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* Edit Event */}
      {selectedEvent && (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpenEditEvent1}
          onClose={onCloseEditEvent1}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              style={{
                background: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
                color: "white",
                textAlign: "center",
                borderRadius: "5px 5px 0 0",
              }}
            >
              Edit Enquiry
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Grid
                templateColumns={{ base: "1fr", md: "1fr 1fr", sm: "1fr" }}
                gap={4}
              >
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Full name</FormLabel>
                  <Input
                    name="full_name"
                    ref={initialRef}
                    placeholder={selectedEvent.full_name}
                    onChange={handleInputChange}
                    value={formData.full_name}
                  />
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    placeholder={selectedEvent.email}
                    onChange={handleInputChange}
                    value={formData.email}
                  />
                </FormControl>
                <FormControl
                    gridColumn={{
                      base: "1 / span 2",
                      md: "auto",
                    }}
                  >
                    <FormLabel>Enquiry Date</FormLabel>
                    <Input
                      name="appointmentDate"
                      type="date"
                      border="1px solid black"
                      placeholder={formData.appointmentDate}
                      min={new Date().toISOString().slice(0, 10)}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Enquiry Status</FormLabel>
                  <Select
                    name="eventStatus"
                    value={formData.eventStatus || selectedEvent.eventStatus}
                    onChange={handleInputChange}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Completed">Completed</option>
                  </Select>
                </FormControl>

                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Payment</FormLabel>
                  <Select
                    name="paymentStatus"
                    value={
                      formData.paymentStatus || selectedEvent.paymentStatus
                    }
                    onChange={handleInputChange}
                    defaultValue={
                      selectedEvent.paymentStatus ? "paid" : "notPaid"
                    }
                  >
                    <option value={false}>Not Paid</option>
                    <option value={true}>Paid</option>
                  </Select>
                </FormControl>
                <FormControl gridColumn={{ base: "1 / span 2", md: "auto" }}>
                  <FormLabel>Amount</FormLabel>
                  <Input
                    placeholder={selectedEvent.ammount}
                    name="ammount"
                    type="number"
                    onChange={handleInputChange}
                    value={formData.ammount}
                  />
                </FormControl>
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button
                _hover={{
                  color: "orange",
                  bg: "transparent",
                }}
                color="white"
                gb="brown"
                mr={3}
                onClick={() => handleSubmitEvent()}
                backgroundColor="gold"
              >
                Save
              </Button>
              <Button
                _hover={{
                  color: "orange",
                  bg: "transparent",
                }}
                onClick={onCloseEditEvent1}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {/* Edit User */}

      <AlertDialog
        isOpen={isDeleteAlertOpenEvent}
        leastDestructiveRef={finalRef}
        onClose={() => setIsDeleteAlertOpenEvent(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this Enquiry?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={finalRef}
                onClick={() => setIsDeleteAlertOpenEvent(false)}
              >
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onDeleteConfirmEvent} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ChakraProvider>
  );
}
