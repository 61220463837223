import React, { useState } from "react";
import Swal from "sweetalert2";
import {
  ChakraProvider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Grid,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Text,
} from "@chakra-ui/react";
import axios from "axios";

const SpecialisedServicesSection = ({
  title,
  imageSrc,
  services,
  index,
  id,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    phone: "",
    email: "",
    message: "",
    appointmentDate: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "appointmentDate") {
      const [year, month, day] = value.split("-");
      const formattedDate = `${day}/${month}/${year}`;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formattedDate,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    // Add your form submission logic here
    // For example, validation, sending data to the server, etc.
    // Reset the form data and close the modal after submission
    axios
      .post("https://fierce-worm-boot.cyclic.app/event", {
        ...formData,
        eventName: title,
        eventId: id,
      })
      .then((res) => {
        console.log(res)
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your form has been submitted successfully!",
          confirmButtonColor:
            "linear-gradient(rgb(29, 86, 153), rgb(29, 86, 153), rgb(52, 103, 163), rgb(74, 120, 173), rgb(74, 120, 173), rgb(52, 103, 163), rgb(29, 86, 153), rgb(29, 86, 153))",
          confirmButtonText: "OK",
        });
        setFormData({
          full_name: "",
          phone: "",
          email: "",
          message: "",
          appointmentDate: "",
        });
        setIsOpen(false);
      })
      .catch((err) =>
        {
          
          Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong. Please try again later!",
          confirmButtonColor:
            "linear-gradient(rgb(29, 86, 153), rgb(29, 86, 153), rgb(52, 103, 163), rgb(74, 120, 173), rgb(74, 120, 173), rgb(52, 103, 163), rgb(29, 86, 153), rgb(29, 86, 153))",
          confirmButtonText: "OK",
        })}
      );
    
  };

  return (
    <div className="content-box">
      <div className="row">
        {index % 2 === 0 ? (
          <>
            <div
              className="col-lg-5 col-sm-6"
              style={{
                paddingTop: "50px",
                width: "90%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <ul>
                {services.map((service, index) => (
                  <li key={index}>{service}</li>
                ))}
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <img src={imageSrc} alt={`Specialised Services - ${title}`} />
            </div>
            <div>
              <button
                className="book_service_btn"
                onClick={() => setIsOpen(true)}
              >
                Get in touch
              </button>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <img src={imageSrc} alt={`Specialised Services - ${title}`} />
            </div>
            <div
              className="col-lg-5 col-sm-6"
              style={{
                paddingTop: "50px",
                width: "90%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <ul>
                {services.map((service, index) => (
                  <li key={index}>{service}</li>
                ))}
              </ul>
            </div>
            <div>
              <button
                className="book_service_btn"
                onClick={() => setIsOpen(true)}
              >
                Get in touch
              </button>
            </div>
          </>
        )}
        <ChakraProvider>
          <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="xl">
            <ModalOverlay />
            <ModalContent
              style={{
                background: "white",
              }}
            >
              <ModalHeader
                style={{
                  backgroundImage:
                    "linear-gradient(360deg, #011b32 20%, #009ffd 100%)",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                {title}
              </ModalHeader>
              <ModalCloseButton color={"white"} />
              <ModalBody pb={6}>
                <Grid
                  templateColumns={{
                    base: "1fr",
                    md: "1fr 1fr",
                    sm: "1fr",
                  }}
                  gap={4}
                >
                  <FormControl
                    gridColumn={{
                      base: "1 / span 2",
                      md: "auto",
                    }}
                  >
                    <FormLabel>Full name</FormLabel>
                    <Input
                      name="full_name"
                      placeholder="Full name"
                      onChange={handleInputChange}
                      value={formData.full_name}
                      required
                      border="1px solid black"
                      isInvalid={!!formErrors.full_name}
                    />
                    {formErrors.full_name && (
                      <Text color="red.500" fontSize="sm">
                        {formErrors.full_name}
                      </Text>
                    )}
                  </FormControl>

                  <FormControl
                    gridColumn={{
                      base: "1 / span 2",
                      md: "auto",
                    }}
                  >
                    <FormLabel>Mobile number</FormLabel>
                    <Input
                      name="phone"
                      border="1px solid black"
                      placeholder="Mobile number"
                      onChange={handleInputChange}
                      value={formData.phone}
                      isInvalid={!!formErrors.phone}
                    />
                    {formErrors.phone && (
                      <Text color="red.500" fontSize="sm">
                        {formErrors.phone}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl
                    gridColumn={{
                      base: "1 / span 2",
                      md: "auto",
                    }}
                  >
                    <FormLabel>Email</FormLabel>
                    <Input
                      name="email"
                      border="1px solid black"
                      placeholder="Enter Your Email"
                      onChange={handleInputChange}
                      value={formData.email}
                      isInvalid={!!formErrors.email}
                    />
                  </FormControl>

                  <FormControl
                    gridColumn={{
                      base: "1 / span 2",
                      md: "auto",
                    }}
                  >
                    <FormLabel>Appointment Date</FormLabel>
                    <Input
                      name="appointmentDate"
                      type="date"
                      border="1px solid black"
                      placeholder={formData.appointmentDate}
                      onChange={handleInputChange}
                      isInvalid={!!formErrors.appointmentDate}
                    />
                  </FormControl>

                  <FormControl
                    gridColumn={{
                      base: "1 / span 2",
                      md: "1 / span 2",
                    }}
                  >
                    <FormLabel>Message</FormLabel>
                    <Textarea
                      placeholder="Message"
                      name="message"
                      border="1px solid black"
                      onChange={handleInputChange}
                      value={formData.message}
                      isInvalid={!!formErrors.message}
                    />
                    {formErrors.message && (
                      <Text color="red.500" fontSize="sm">
                        {formErrors.message}
                      </Text>
                    )}
                  </FormControl>
                </Grid>
              </ModalBody>

              <ModalFooter>
                <Button
                  mr={3}
                  onClick={handleSubmit}
                  bg="linear-gradient(360deg, #011b32 20%, #009ffd 100%)"
                  color="white"
                >
                  Submit
                </Button>
                <Button
                  onClick={() => setIsOpen(false)}
                  bg="linear-gradient(360deg, #011b32 20%, #009ffd 100%)"
                  color="white"
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </ChakraProvider>
      </div>
    </div>
  );
};

export default SpecialisedServicesSection;
