import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Home'
import Viwe from '../Body/Viwe&voice/Viwe'
import AboutUs from '../Body/AboutUs/AboutUs'
import AuditAndCompliance from '../Body/AuditAndCompliance/AuditAndCompliance'
import Contact from '../Body/Contact/Contact'
import Resources from '../Body/Resources/Resources'
import AdminNav from '../Admin/AdminNav/AdminNav'
import Allusers from '../Admin/AllUsers/Allusers'
import Events from '../Admin/Events/Events'
import UserDetailsCard from '../Admin/UserDetailsCard/UserDetailsCard'
import Allservices from '../Admin/Services/Allservices'
import SingleEventDetails from '../Admin/Events/SingleEventDetails'
import AdminLogin from '../Admin/AdminNav/Login'

export default function AllPages() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/view-and-voice" element={<Viwe />}></Route>
      <Route path="/aboutus/:id" element={<AboutUs />}></Route>
      <Route path="/auditAndCompliance/:id" element={<AuditAndCompliance />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/resources/:id" element={<Resources />}></Route>
      <Route path="/admin/home" element={<AdminLogin />}></Route>
       <Route path="/admin/users" element={<Allusers />}></Route>
      <Route path="admin/Services" element={<Events/>}></Route>
      <Route path="/admin/usersDtails/:id" element={<UserDetailsCard/>}></Route>
      <Route path="/admin/allservice" element={<Allservices/>}></Route>
      <Route path="/admin/userEvents/:id" element={<SingleEventDetails />}></Route>
    </Routes>
  )
}
