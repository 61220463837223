import React from 'react'
import Slider from './Body/Slider/Slider'
import ContentPart from './Body/Slider/ContentPart'
import Products from './Body/Product/Products'
import About from './Body/About/About/About'
import BlogSlider from './Body/About/Card/Blog'
import Testimonial from './Body/AboutUs/HomeAbout/Testimonial'


export default function Home() {
  window.scroll(0,0)
  return (
    <>
      <Slider />
      {/* <ContentPart /> */}
      {/* <About /> */}
      <BlogSlider/>
      <br></br>
      <Products />
      <br></br>
      {/* <AboutCard/>
      <br></br> */}
      <Testimonial/>
    </>
  )
}
