import React from 'react'
import { Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
export default function ExternalLink() {
  return (
    <>
    <Link href='https://www.incometax.gov.in/' isExternal bg="white" color="black"style={{height:"fit-content",width:"100%",padding:"20px",borderRadius:"5px"}} >
    <div >
        
  IncomeTex <ExternalLinkIcon mx='2px' />

    </div>
    </Link>




    <Link href='https://www.gst.gov.in/' isExternal bg="white" color="black"style={{height:"fit-content",width:"100%",padding:"20px",borderRadius:"5px"}} >
    <div >
        
    Goods and Services Tax <ExternalLinkIcon mx='2px' />

    </div>
    </Link>


    <Link href='https://www.rbi.org.in/' isExternal bg="white" color="black"style={{height:"fit-content",width:"100%",padding:"20px",borderRadius:"5px"}} >
    <div >
        
    Reserve Bank of India<ExternalLinkIcon mx='2px' />

    </div>
    </Link>


    <Link href='https://www.sebi.gov.in/' isExternal bg="white" color="black"style={{height:"fit-content",width:"100%",padding:"20px",borderRadius:"5px"}} >
    <div >
        
    Securities and Exchange Board of India
<ExternalLinkIcon mx='2px' />

    </div>
    </Link>

    <Link href='https://www.icai.org/' isExternal bg="white" color="black"style={{height:"fit-content",width:"100%",padding:"20px",borderRadius:"5px"}} >
    <div >
        
    The Institute of Chartered Accountants of India<ExternalLinkIcon mx='2px' />

    </div>
    </Link>



    <Link href='https://www.mca.gov.in/' isExternal bg="white" color="black"style={{height:"fit-content",width:"100%",padding:"20px",borderRadius:"5px"}} >
    <div >
        
    Ministry of Corporate Affairs.<ExternalLinkIcon mx='2px' />

    </div>
    </Link>
    
    </>
  )
}
